/* eslint-disable */
// @ts-nocheck
/**
 * Generated by @openapi-codegen
 *
 * @version 0.1.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useOneApiContext, OneApiContext } from './oneApiContext';
import type * as Fetcher from './oneApiFetcher';
import { oneApiFetch } from './oneApiFetcher';
import type * as Schemas from './oneApiSchemas';
import type * as RequestBodies from './oneApiRequestBodies';

export type AiAssistantConfigListError = Fetcher.ErrorWrapper<undefined>;

export type AiAssistantConfigListResponse = Schemas.AiAssistantConfig[];

export type AiAssistantConfigListVariables = OneApiContext['fetcherOptions'];

export const fetchAiAssistantConfigList = (
  variables: AiAssistantConfigListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    AiAssistantConfigListResponse,
    AiAssistantConfigListError,
    undefined,
    {},
    {},
    {}
  >({ url: '/ai-assistant-config', method: 'get', ...variables, signal });

export const useAiAssistantConfigList = <TData = AiAssistantConfigListResponse>(
  variables: AiAssistantConfigListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      AiAssistantConfigListResponse,
      AiAssistantConfigListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    AiAssistantConfigListResponse,
    AiAssistantConfigListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ai-assistant-config',
      operationId: 'aiAssistantConfigList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAiAssistantConfigList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AiAssistantConfigCreateError = Fetcher.ErrorWrapper<undefined>;

export type AiAssistantConfigCreateVariables = {
  body?: RequestBodies.AiAssistantConfig;
} & OneApiContext['fetcherOptions'];

export const fetchAiAssistantConfigCreate = (
  variables: AiAssistantConfigCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AiAssistantConfig,
    AiAssistantConfigCreateError,
    RequestBodies.AiAssistantConfig,
    {},
    {},
    {}
  >({ url: '/ai-assistant-config', method: 'post', ...variables, signal });

export const useAiAssistantConfigCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AiAssistantConfig,
      AiAssistantConfigCreateError,
      AiAssistantConfigCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.AiAssistantConfig,
    AiAssistantConfigCreateError,
    AiAssistantConfigCreateVariables
  >({
    mutationFn: (variables: AiAssistantConfigCreateVariables) =>
      fetchAiAssistantConfigCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AiAssistantConfigCollaborationListError =
  Fetcher.ErrorWrapper<undefined>;

export type AiAssistantConfigCollaborationListResponse =
  Schemas.AiAssistantConfig[];

export type AiAssistantConfigCollaborationListVariables =
  OneApiContext['fetcherOptions'];

export const fetchAiAssistantConfigCollaborationList = (
  variables: AiAssistantConfigCollaborationListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    AiAssistantConfigCollaborationListResponse,
    AiAssistantConfigCollaborationListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/ai-assistant-config/collaboration',
    method: 'get',
    ...variables,
    signal,
  });

export const useAiAssistantConfigCollaborationList = <
  TData = AiAssistantConfigCollaborationListResponse
>(
  variables: AiAssistantConfigCollaborationListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      AiAssistantConfigCollaborationListResponse,
      AiAssistantConfigCollaborationListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    AiAssistantConfigCollaborationListResponse,
    AiAssistantConfigCollaborationListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ai-assistant-config/collaboration',
      operationId: 'aiAssistantConfigCollaborationList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAiAssistantConfigCollaborationList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type AiAssistantConfigReadPathParams = {
  /**
   * A UUID string identifying this ai assistant config.
   *
   * @format uuid
   */
  id: string;
};

export type AiAssistantConfigReadError = Fetcher.ErrorWrapper<undefined>;

export type AiAssistantConfigReadVariables = {
  pathParams: AiAssistantConfigReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAiAssistantConfigRead = (
  variables: AiAssistantConfigReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AiAssistantConfig,
    AiAssistantConfigReadError,
    undefined,
    {},
    {},
    AiAssistantConfigReadPathParams
  >({ url: '/ai-assistant-config/{id}', method: 'get', ...variables, signal });

export const useAiAssistantConfigRead = <TData = Schemas.AiAssistantConfig>(
  variables: AiAssistantConfigReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AiAssistantConfig,
      AiAssistantConfigReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.AiAssistantConfig,
    AiAssistantConfigReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ai-assistant-config/{id}',
      operationId: 'aiAssistantConfigRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAiAssistantConfigRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AiAssistantConfigUpdatePathParams = {
  /**
   * A UUID string identifying this ai assistant config.
   *
   * @format uuid
   */
  id: string;
};

export type AiAssistantConfigUpdateError = Fetcher.ErrorWrapper<undefined>;

export type AiAssistantConfigUpdateVariables = {
  body?: RequestBodies.AiAssistantConfig;
  pathParams: AiAssistantConfigUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAiAssistantConfigUpdate = (
  variables: AiAssistantConfigUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AiAssistantConfig,
    AiAssistantConfigUpdateError,
    RequestBodies.AiAssistantConfig,
    {},
    {},
    AiAssistantConfigUpdatePathParams
  >({ url: '/ai-assistant-config/{id}', method: 'put', ...variables, signal });

export const useAiAssistantConfigUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AiAssistantConfig,
      AiAssistantConfigUpdateError,
      AiAssistantConfigUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.AiAssistantConfig,
    AiAssistantConfigUpdateError,
    AiAssistantConfigUpdateVariables
  >({
    mutationFn: (variables: AiAssistantConfigUpdateVariables) =>
      fetchAiAssistantConfigUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AiAssistantConfigDeletePathParams = {
  /**
   * A UUID string identifying this ai assistant config.
   *
   * @format uuid
   */
  id: string;
};

export type AiAssistantConfigDeleteError = Fetcher.ErrorWrapper<undefined>;

export type AiAssistantConfigDeleteVariables = {
  pathParams: AiAssistantConfigDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAiAssistantConfigDelete = (
  variables: AiAssistantConfigDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    AiAssistantConfigDeleteError,
    undefined,
    {},
    {},
    AiAssistantConfigDeletePathParams
  >({
    url: '/ai-assistant-config/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useAiAssistantConfigDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      AiAssistantConfigDeleteError,
      AiAssistantConfigDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    AiAssistantConfigDeleteError,
    AiAssistantConfigDeleteVariables
  >({
    mutationFn: (variables: AiAssistantConfigDeleteVariables) =>
      fetchAiAssistantConfigDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AiAssistantConfigHistoryListPathParams = {
  id: string;
};

export type AiAssistantConfigHistoryListError = Fetcher.ErrorWrapper<undefined>;

export type AiAssistantConfigHistoryListResponse =
  Schemas.AiAssistantConfigHistory[];

export type AiAssistantConfigHistoryListVariables = {
  pathParams: AiAssistantConfigHistoryListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAiAssistantConfigHistoryList = (
  variables: AiAssistantConfigHistoryListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    AiAssistantConfigHistoryListResponse,
    AiAssistantConfigHistoryListError,
    undefined,
    {},
    {},
    AiAssistantConfigHistoryListPathParams
  >({
    url: '/ai-assistant-config/{id}/history',
    method: 'get',
    ...variables,
    signal,
  });

export const useAiAssistantConfigHistoryList = <
  TData = AiAssistantConfigHistoryListResponse
>(
  variables: AiAssistantConfigHistoryListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      AiAssistantConfigHistoryListResponse,
      AiAssistantConfigHistoryListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    AiAssistantConfigHistoryListResponse,
    AiAssistantConfigHistoryListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/ai-assistant-config/{id}/history',
      operationId: 'aiAssistantConfigHistoryList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAiAssistantConfigHistoryList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type AssessmentQuestionCreateError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentQuestionCreateVariables = {
  body: RequestBodies.AssessmentQuestion;
} & OneApiContext['fetcherOptions'];

export const fetchAssessmentQuestionCreate = (
  variables: AssessmentQuestionCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AssessmentQuestion,
    AssessmentQuestionCreateError,
    RequestBodies.AssessmentQuestion,
    {},
    {},
    {}
  >({ url: '/assessment-question', method: 'post', ...variables, signal });

export const useAssessmentQuestionCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssessmentQuestion,
      AssessmentQuestionCreateError,
      AssessmentQuestionCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.AssessmentQuestion,
    AssessmentQuestionCreateError,
    AssessmentQuestionCreateVariables
  >({
    mutationFn: (variables: AssessmentQuestionCreateVariables) =>
      fetchAssessmentQuestionCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AssessmentQuestionReadPathParams = {
  /**
   * A UUID string identifying this assessment question.
   *
   * @format uuid
   */
  id: string;
};

export type AssessmentQuestionReadError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentQuestionReadVariables = {
  pathParams: AssessmentQuestionReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAssessmentQuestionRead = (
  variables: AssessmentQuestionReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AssessmentQuestion,
    AssessmentQuestionReadError,
    undefined,
    {},
    {},
    AssessmentQuestionReadPathParams
  >({ url: '/assessment-question/{id}', method: 'get', ...variables, signal });

export const useAssessmentQuestionRead = <TData = Schemas.AssessmentQuestion>(
  variables: AssessmentQuestionReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AssessmentQuestion,
      AssessmentQuestionReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.AssessmentQuestion,
    AssessmentQuestionReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/assessment-question/{id}',
      operationId: 'assessmentQuestionRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAssessmentQuestionRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type AssessmentQuestionUpdatePathParams = {
  /**
   * A UUID string identifying this assessment question.
   *
   * @format uuid
   */
  id: string;
};

export type AssessmentQuestionUpdateError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentQuestionUpdateVariables = {
  body: RequestBodies.AssessmentQuestion;
  pathParams: AssessmentQuestionUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchAssessmentQuestionUpdate = (
  variables: AssessmentQuestionUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.AssessmentQuestion,
    AssessmentQuestionUpdateError,
    RequestBodies.AssessmentQuestion,
    {},
    {},
    AssessmentQuestionUpdatePathParams
  >({ url: '/assessment-question/{id}', method: 'put', ...variables, signal });

export const useAssessmentQuestionUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssessmentQuestion,
      AssessmentQuestionUpdateError,
      AssessmentQuestionUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.AssessmentQuestion,
    AssessmentQuestionUpdateError,
    AssessmentQuestionUpdateVariables
  >({
    mutationFn: (variables: AssessmentQuestionUpdateVariables) =>
      fetchAssessmentQuestionUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CollaborationPermissionCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaborationPermissionCreateRequestBody = {
  /**
   * channelId as base64 json object
   */
  channelId: {
    projectId?: void;
    /**
     * 'Project' | 'Finding' | 'MaturityRating' | 'FindingTopic' | 'FindingArea'
     */
    entity?: string;
    id?: void;
    /**
     * entity field
     */
    field?: string;
  };
  /**
   * Valid JWT of the end user.
   */
  endUserToken: string;
};

export type CollaborationPermissionCreateVariables = {
  body: CollaborationPermissionCreateRequestBody;
} & OneApiContext['fetcherOptions'];

/**
 * Validates if token can access to given channelId
 */
export const fetchCollaborationPermissionCreate = (
  variables: CollaborationPermissionCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    CollaborationPermissionCreateError,
    CollaborationPermissionCreateRequestBody,
    {},
    {},
    {}
  >({ url: '/collaboration/permission', method: 'post', ...variables, signal });

/**
 * Validates if token can access to given channelId
 */
export const useCollaborationPermissionCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaborationPermissionCreateError,
      CollaborationPermissionCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaborationPermissionCreateError,
    CollaborationPermissionCreateVariables
  >({
    mutationFn: (variables: CollaborationPermissionCreateVariables) =>
      fetchCollaborationPermissionCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CollaborationPersistentCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type CollaborationPersistentCreateVariables = {
  body: RequestBodies.CollaborationPersistentCreateData;
} & OneApiContext['fetcherOptions'];

/**
 * Saves field of provide object in channelId.
 */
export const fetchCollaborationPersistentCreate = (
  variables: CollaborationPersistentCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    CollaborationPersistentCreateError,
    RequestBodies.CollaborationPersistentCreateData,
    {},
    {},
    {}
  >({ url: '/collaboration/persistent', method: 'post', ...variables, signal });

/**
 * Saves field of provide object in channelId.
 */
export const useCollaborationPersistentCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaborationPersistentCreateError,
      CollaborationPersistentCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaborationPersistentCreateError,
    CollaborationPersistentCreateVariables
  >({
    mutationFn: (variables: CollaborationPersistentCreateVariables) =>
      fetchCollaborationPersistentCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CollaborationRetrieveCreateError = Fetcher.ErrorWrapper<undefined>;

export type CollaborationRetrieveCreateVariables = {
  body: RequestBodies.CollaborationPersistentCreateData;
} & OneApiContext['fetcherOptions'];

/**
 * Returns field of provide object in channelId.
 */
export const fetchCollaborationRetrieveCreate = (
  variables: CollaborationRetrieveCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    CollaborationRetrieveCreateError,
    RequestBodies.CollaborationPersistentCreateData,
    {},
    {},
    {}
  >({ url: '/collaboration/retrieve', method: 'post', ...variables, signal });

/**
 * Returns field of provide object in channelId.
 */
export const useCollaborationRetrieveCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CollaborationRetrieveCreateError,
      CollaborationRetrieveCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    CollaborationRetrieveCreateError,
    CollaborationRetrieveCreateVariables
  >({
    mutationFn: (variables: CollaborationRetrieveCreateVariables) =>
      fetchCollaborationRetrieveCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CommentsListError = Fetcher.ErrorWrapper<undefined>;

export type CommentsListResponse = Schemas.Comment[];

export type CommentsListVariables = OneApiContext['fetcherOptions'];

export const fetchCommentsList = (
  variables: CommentsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<CommentsListResponse, CommentsListError, undefined, {}, {}, {}>({
    url: '/comments',
    method: 'get',
    ...variables,
    signal,
  });

export const useCommentsList = <TData = CommentsListResponse>(
  variables: CommentsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<CommentsListResponse, CommentsListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<CommentsListResponse, CommentsListError, TData>({
    queryKey: queryKeyFn({
      path: '/comments',
      operationId: 'commentsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchCommentsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CommentsCreateError = Fetcher.ErrorWrapper<undefined>;

export type CommentsCreateVariables = {
  body: RequestBodies.Comment;
} & OneApiContext['fetcherOptions'];

export const fetchCommentsCreate = (
  variables: CommentsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Comment,
    CommentsCreateError,
    RequestBodies.Comment,
    {},
    {},
    {}
  >({ url: '/comments', method: 'post', ...variables, signal });

export const useCommentsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Comment,
      CommentsCreateError,
      CommentsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Comment,
    CommentsCreateError,
    CommentsCreateVariables
  >({
    mutationFn: (variables: CommentsCreateVariables) =>
      fetchCommentsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CommentsReadPathParams = {
  /**
   * A UUID string identifying this comment.
   *
   * @format uuid
   */
  id: string;
};

export type CommentsReadError = Fetcher.ErrorWrapper<undefined>;

export type CommentsReadVariables = {
  pathParams: CommentsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchCommentsRead = (
  variables: CommentsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Comment,
    CommentsReadError,
    undefined,
    {},
    {},
    CommentsReadPathParams
  >({ url: '/comments/{id}', method: 'get', ...variables, signal });

export const useCommentsRead = <TData = Schemas.Comment>(
  variables: CommentsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Comment, CommentsReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Comment, CommentsReadError, TData>({
    queryKey: queryKeyFn({
      path: '/comments/{id}',
      operationId: 'commentsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchCommentsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CommentsUpdatePathParams = {
  /**
   * A UUID string identifying this comment.
   *
   * @format uuid
   */
  id: string;
};

export type CommentsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type CommentsUpdateVariables = {
  body: RequestBodies.Comment;
  pathParams: CommentsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchCommentsUpdate = (
  variables: CommentsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Comment,
    CommentsUpdateError,
    RequestBodies.Comment,
    {},
    {},
    CommentsUpdatePathParams
  >({ url: '/comments/{id}', method: 'put', ...variables, signal });

export const useCommentsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Comment,
      CommentsUpdateError,
      CommentsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Comment,
    CommentsUpdateError,
    CommentsUpdateVariables
  >({
    mutationFn: (variables: CommentsUpdateVariables) =>
      fetchCommentsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingAreaFindingTopicsListPathParams = {
  id: string;
};

export type FindingAreaFindingTopicsListError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreaFindingTopicsListResponse =
  Schemas.MaturityFindingTopic[];

export type FindingAreaFindingTopicsListVariables = {
  pathParams: FindingAreaFindingTopicsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreaFindingTopicsList = (
  variables: FindingAreaFindingTopicsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingAreaFindingTopicsListResponse,
    FindingAreaFindingTopicsListError,
    undefined,
    {},
    {},
    FindingAreaFindingTopicsListPathParams
  >({
    url: '/finding-area/{id}/finding-topics',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingAreaFindingTopicsList = <
  TData = FindingAreaFindingTopicsListResponse
>(
  variables: FindingAreaFindingTopicsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingAreaFindingTopicsListResponse,
      FindingAreaFindingTopicsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingAreaFindingTopicsListResponse,
    FindingAreaFindingTopicsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-area/{id}/finding-topics',
      operationId: 'findingAreaFindingTopicsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingAreaFindingTopicsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type FindingAreaRatingListPathParams = {
  id: string;
};

export type FindingAreaRatingListError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreaRatingListVariables = {
  pathParams: FindingAreaRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreaRatingList = (
  variables: FindingAreaRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingAreaRatingListError,
    undefined,
    {},
    {},
    FindingAreaRatingListPathParams
  >({ url: '/finding-area/{id}/rating', method: 'get', ...variables, signal });

export const useFindingAreaRatingList = <TData = undefined>(
  variables: FindingAreaRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, FindingAreaRatingListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, FindingAreaRatingListError, TData>({
    queryKey: queryKeyFn({
      path: '/finding-area/{id}/rating',
      operationId: 'findingAreaRatingList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingAreaRatingList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindingAreasCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasCreateVariables = {
  body: RequestBodies.FindingArea;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasCreate = (
  variables: FindingAreasCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingArea,
    FindingAreasCreateError,
    RequestBodies.FindingArea,
    {},
    {},
    {}
  >({ url: '/finding-areas/', method: 'post', ...variables, signal });

export const useFindingAreasCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingArea,
      FindingAreasCreateError,
      FindingAreasCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingArea,
    FindingAreasCreateError,
    FindingAreasCreateVariables
  >({
    mutationFn: (variables: FindingAreasCreateVariables) =>
      fetchFindingAreasCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingAreasReadPathParams = {
  /**
   * A UUID string identifying this finding area.
   *
   * @format uuid
   */
  id: string;
};

export type FindingAreasReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasReadVariables = {
  pathParams: FindingAreasReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasRead = (
  variables: FindingAreasReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingArea,
    FindingAreasReadError,
    undefined,
    {},
    {},
    FindingAreasReadPathParams
  >({ url: '/finding-areas/{id}', method: 'get', ...variables, signal });

export const useFindingAreasRead = <TData = Schemas.FindingArea>(
  variables: FindingAreasReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingArea,
      FindingAreasReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.FindingArea, FindingAreasReadError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/finding-areas/{id}',
        operationId: 'findingAreasRead',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchFindingAreasRead({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingAreasUpdatePathParams = {
  /**
   * A UUID string identifying this finding area.
   *
   * @format uuid
   */
  id: string;
};

export type FindingAreasUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasUpdateVariables = {
  body: RequestBodies.FindingArea;
  pathParams: FindingAreasUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasUpdate = (
  variables: FindingAreasUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingArea,
    FindingAreasUpdateError,
    RequestBodies.FindingArea,
    {},
    {},
    FindingAreasUpdatePathParams
  >({ url: '/finding-areas/{id}', method: 'put', ...variables, signal });

export const useFindingAreasUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingArea,
      FindingAreasUpdateError,
      FindingAreasUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingArea,
    FindingAreasUpdateError,
    FindingAreasUpdateVariables
  >({
    mutationFn: (variables: FindingAreasUpdateVariables) =>
      fetchFindingAreasUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingAreasDeletePathParams = {
  /**
   * A UUID string identifying this finding area.
   *
   * @format uuid
   */
  id: string;
};

export type FindingAreasDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingAreasDeleteVariables = {
  pathParams: FindingAreasDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingAreasDelete = (
  variables: FindingAreasDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingAreasDeleteError,
    undefined,
    {},
    {},
    FindingAreasDeletePathParams
  >({ url: '/finding-areas/{id}', method: 'delete', ...variables, signal });

export const useFindingAreasDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingAreasDeleteError,
      FindingAreasDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingAreasDeleteError,
    FindingAreasDeleteVariables
  >({
    mutationFn: (variables: FindingAreasDeleteVariables) =>
      fetchFindingAreasDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingGroupsReadPathParams = {
  /**
   * A UUID string identifying this finding group.
   *
   * @format uuid
   */
  id: string;
};

export type FindingGroupsReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsReadVariables = {
  pathParams: FindingGroupsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsRead = (
  variables: FindingGroupsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingGroup,
    FindingGroupsReadError,
    undefined,
    {},
    {},
    FindingGroupsReadPathParams
  >({ url: '/finding-groups/{id}', method: 'get', ...variables, signal });

export const useFindingGroupsRead = <TData = Schemas.FindingGroup>(
  variables: FindingGroupsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingGroup,
      FindingGroupsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingGroup,
    FindingGroupsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-groups/{id}',
      operationId: 'findingGroupsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingGroupsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindingGroupsFindingAreasListPathParams = {
  id: string;
};

export type FindingGroupsFindingAreasListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsFindingAreasListResponse = Schemas.FindingArea[];

export type FindingGroupsFindingAreasListVariables = {
  pathParams: FindingGroupsFindingAreasListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsFindingAreasList = (
  variables: FindingGroupsFindingAreasListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingGroupsFindingAreasListResponse,
    FindingGroupsFindingAreasListError,
    undefined,
    {},
    {},
    FindingGroupsFindingAreasListPathParams
  >({
    url: '/finding-groups/{id}/finding-areas',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingGroupsFindingAreasList = <
  TData = FindingGroupsFindingAreasListResponse
>(
  variables: FindingGroupsFindingAreasListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingGroupsFindingAreasListResponse,
      FindingGroupsFindingAreasListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingGroupsFindingAreasListResponse,
    FindingGroupsFindingAreasListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-groups/{id}/finding-areas',
      operationId: 'findingGroupsFindingAreasList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingGroupsFindingAreasList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type FindingGroupsRatingListPathParams = {
  id: string;
};

export type FindingGroupsRatingListError = Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsRatingListVariables = {
  pathParams: FindingGroupsRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsRatingList = (
  variables: FindingGroupsRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingGroupsRatingListError,
    undefined,
    {},
    {},
    FindingGroupsRatingListPathParams
  >({
    url: '/finding-groups/{id}/rating',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingGroupsRatingList = <TData = undefined>(
  variables: FindingGroupsRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, FindingGroupsRatingListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, FindingGroupsRatingListError, TData>({
    queryKey: queryKeyFn({
      path: '/finding-groups/{id}/rating',
      operationId: 'findingGroupsRatingList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingGroupsRatingList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindingGroupsTasRatingListPathParams = {
  id: string;
};

export type FindingGroupsTasRatingListError = Fetcher.ErrorWrapper<undefined>;

export type FindingGroupsTasRatingListVariables = {
  pathParams: FindingGroupsTasRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingGroupsTasRatingList = (
  variables: FindingGroupsTasRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingGroupsTasRatingListError,
    undefined,
    {},
    {},
    FindingGroupsTasRatingListPathParams
  >({
    url: '/finding-groups/{id}/tas_rating',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingGroupsTasRatingList = <TData = undefined>(
  variables: FindingGroupsTasRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      FindingGroupsTasRatingListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, FindingGroupsTasRatingListError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/finding-groups/{id}/tas_rating',
        operationId: 'findingGroupsTasRatingList',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchFindingGroupsTasRatingList(
          { ...fetcherOptions, ...variables },
          signal
        ),
      ...options,
      ...queryOptions,
    }
  );
};

export type FindingReferenceCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceCreateVariables = {
  body: RequestBodies.FindingReference;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceCreate = (
  variables: FindingReferenceCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingReference,
    FindingReferenceCreateError,
    RequestBodies.FindingReference,
    {},
    {},
    {}
  >({ url: '/finding-reference', method: 'post', ...variables, signal });

export const useFindingReferenceCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingReference,
      FindingReferenceCreateError,
      FindingReferenceCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingReference,
    FindingReferenceCreateError,
    FindingReferenceCreateVariables
  >({
    mutationFn: (variables: FindingReferenceCreateVariables) =>
      fetchFindingReferenceCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingReferenceReadPathParams = {
  /**
   * A UUID string identifying this finding reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingReferenceReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceReadVariables = {
  pathParams: FindingReferenceReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceRead = (
  variables: FindingReferenceReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingReference,
    FindingReferenceReadError,
    undefined,
    {},
    {},
    FindingReferenceReadPathParams
  >({ url: '/finding-reference/{id}', method: 'get', ...variables, signal });

export const useFindingReferenceRead = <TData = Schemas.FindingReference>(
  variables: FindingReferenceReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingReference,
      FindingReferenceReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingReference,
    FindingReferenceReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-reference/{id}',
      operationId: 'findingReferenceRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingReferenceRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindingReferenceUpdatePathParams = {
  /**
   * A UUID string identifying this finding reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingReferenceUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceUpdateVariables = {
  body: RequestBodies.FindingReference;
  pathParams: FindingReferenceUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceUpdate = (
  variables: FindingReferenceUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingReference,
    FindingReferenceUpdateError,
    RequestBodies.FindingReference,
    {},
    {},
    FindingReferenceUpdatePathParams
  >({ url: '/finding-reference/{id}', method: 'put', ...variables, signal });

export const useFindingReferenceUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingReference,
      FindingReferenceUpdateError,
      FindingReferenceUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingReference,
    FindingReferenceUpdateError,
    FindingReferenceUpdateVariables
  >({
    mutationFn: (variables: FindingReferenceUpdateVariables) =>
      fetchFindingReferenceUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingReferenceDeletePathParams = {
  /**
   * A UUID string identifying this finding reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingReferenceDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingReferenceDeleteVariables = {
  pathParams: FindingReferenceDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingReferenceDelete = (
  variables: FindingReferenceDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingReferenceDeleteError,
    undefined,
    {},
    {},
    FindingReferenceDeletePathParams
  >({ url: '/finding-reference/{id}', method: 'delete', ...variables, signal });

export const useFindingReferenceDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingReferenceDeleteError,
      FindingReferenceDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingReferenceDeleteError,
    FindingReferenceDeleteVariables
  >({
    mutationFn: (variables: FindingReferenceDeleteVariables) =>
      fetchFindingReferenceDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingTopicReferenceCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceCreateVariables = {
  body: RequestBodies.FindingTopicReference;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceCreate = (
  variables: FindingTopicReferenceCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopicReference,
    FindingTopicReferenceCreateError,
    RequestBodies.FindingTopicReference,
    {},
    {},
    {}
  >({ url: '/finding-topic-reference', method: 'post', ...variables, signal });

export const useFindingTopicReferenceCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingTopicReference,
      FindingTopicReferenceCreateError,
      FindingTopicReferenceCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingTopicReference,
    FindingTopicReferenceCreateError,
    FindingTopicReferenceCreateVariables
  >({
    mutationFn: (variables: FindingTopicReferenceCreateVariables) =>
      fetchFindingTopicReferenceCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingTopicReferenceReadPathParams = {
  /**
   * A UUID string identifying this finding topic reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReferenceReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceReadVariables = {
  pathParams: FindingTopicReferenceReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceRead = (
  variables: FindingTopicReferenceReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopicReference,
    FindingTopicReferenceReadError,
    undefined,
    {},
    {},
    FindingTopicReferenceReadPathParams
  >({
    url: '/finding-topic-reference/{id}',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicReferenceRead = <
  TData = Schemas.FindingTopicReference
>(
  variables: FindingTopicReferenceReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingTopicReference,
      FindingTopicReferenceReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingTopicReference,
    FindingTopicReferenceReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-topic-reference/{id}',
      operationId: 'findingTopicReferenceRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingTopicReferenceRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type FindingTopicReferenceUpdatePathParams = {
  /**
   * A UUID string identifying this finding topic reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReferenceUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceUpdateVariables = {
  body: RequestBodies.FindingTopicReference;
  pathParams: FindingTopicReferenceUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceUpdate = (
  variables: FindingTopicReferenceUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopicReference,
    FindingTopicReferenceUpdateError,
    RequestBodies.FindingTopicReference,
    {},
    {},
    FindingTopicReferenceUpdatePathParams
  >({
    url: '/finding-topic-reference/{id}',
    method: 'put',
    ...variables,
    signal,
  });

export const useFindingTopicReferenceUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingTopicReference,
      FindingTopicReferenceUpdateError,
      FindingTopicReferenceUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingTopicReference,
    FindingTopicReferenceUpdateError,
    FindingTopicReferenceUpdateVariables
  >({
    mutationFn: (variables: FindingTopicReferenceUpdateVariables) =>
      fetchFindingTopicReferenceUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingTopicReferenceDeletePathParams = {
  /**
   * A UUID string identifying this finding topic reference.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReferenceDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReferenceDeleteVariables = {
  pathParams: FindingTopicReferenceDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicReferenceDelete = (
  variables: FindingTopicReferenceDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingTopicReferenceDeleteError,
    undefined,
    {},
    {},
    FindingTopicReferenceDeletePathParams
  >({
    url: '/finding-topic-reference/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useFindingTopicReferenceDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingTopicReferenceDeleteError,
      FindingTopicReferenceDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingTopicReferenceDeleteError,
    FindingTopicReferenceDeleteVariables
  >({
    mutationFn: (variables: FindingTopicReferenceDeleteVariables) =>
      fetchFindingTopicReferenceDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingTopicReadPathParams = {
  /**
   * A UUID string identifying this finding topic.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicReadVariables = {
  pathParams: FindingTopicReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicRead = (
  variables: FindingTopicReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopic,
    FindingTopicReadError,
    undefined,
    {},
    {},
    FindingTopicReadPathParams
  >({ url: '/finding-topic/{id}', method: 'get', ...variables, signal });

export const useFindingTopicRead = <TData = Schemas.FindingTopic>(
  variables: FindingTopicReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FindingTopic,
      FindingTopicReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FindingTopic,
    FindingTopicReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-topic/{id}',
      operationId: 'findingTopicRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingTopicRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindingTopicUpdatePathParams = {
  /**
   * A UUID string identifying this finding topic.
   *
   * @format uuid
   */
  id: string;
};

export type FindingTopicUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingTopicUpdateVariables = {
  body: Schemas.FindingTopic;
  pathParams: FindingTopicUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicUpdate = (
  variables: FindingTopicUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FindingTopic,
    FindingTopicUpdateError,
    Schemas.FindingTopic,
    {},
    {},
    FindingTopicUpdatePathParams
  >({ url: '/finding-topic/{id}', method: 'put', ...variables, signal });

export const useFindingTopicUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.FindingTopic,
      FindingTopicUpdateError,
      FindingTopicUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.FindingTopic,
    FindingTopicUpdateError,
    FindingTopicUpdateVariables
  >({
    mutationFn: (variables: FindingTopicUpdateVariables) =>
      fetchFindingTopicUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingTopicFindingTopicReferencesListPathParams = {
  id: string;
};

export type FindingTopicFindingTopicReferencesListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicFindingTopicReferencesListResponse =
  Schemas.FindingTopicReference[];

export type FindingTopicFindingTopicReferencesListVariables = {
  pathParams: FindingTopicFindingTopicReferencesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicFindingTopicReferencesList = (
  variables: FindingTopicFindingTopicReferencesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingTopicFindingTopicReferencesListResponse,
    FindingTopicFindingTopicReferencesListError,
    undefined,
    {},
    {},
    FindingTopicFindingTopicReferencesListPathParams
  >({
    url: '/finding-topic/{id}/finding-topic-references',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicFindingTopicReferencesList = <
  TData = FindingTopicFindingTopicReferencesListResponse
>(
  variables: FindingTopicFindingTopicReferencesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingTopicFindingTopicReferencesListResponse,
      FindingTopicFindingTopicReferencesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingTopicFindingTopicReferencesListResponse,
    FindingTopicFindingTopicReferencesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-topic/{id}/finding-topic-references',
      operationId: 'findingTopicFindingTopicReferencesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingTopicFindingTopicReferencesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type FindingTopicMaturityRatingsListPathParams = {
  id: string;
};

export type FindingTopicMaturityRatingsListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicMaturityRatingsListResponse = Schemas.MaturityRating[];

export type FindingTopicMaturityRatingsListVariables = {
  pathParams: FindingTopicMaturityRatingsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicMaturityRatingsList = (
  variables: FindingTopicMaturityRatingsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingTopicMaturityRatingsListResponse,
    FindingTopicMaturityRatingsListError,
    undefined,
    {},
    {},
    FindingTopicMaturityRatingsListPathParams
  >({
    url: '/finding-topic/{id}/maturity-ratings',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicMaturityRatingsList = <
  TData = FindingTopicMaturityRatingsListResponse
>(
  variables: FindingTopicMaturityRatingsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingTopicMaturityRatingsListResponse,
      FindingTopicMaturityRatingsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingTopicMaturityRatingsListResponse,
    FindingTopicMaturityRatingsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-topic/{id}/maturity-ratings',
      operationId: 'findingTopicMaturityRatingsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingTopicMaturityRatingsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type FindingTopicMaturityRatingsCreatePathParams = {
  id: string;
};

export type FindingTopicMaturityRatingsCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicMaturityRatingsCreateVariables = {
  body: RequestBodies.MaturityRating;
  pathParams: FindingTopicMaturityRatingsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicMaturityRatingsCreate = (
  variables: FindingTopicMaturityRatingsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityRating,
    FindingTopicMaturityRatingsCreateError,
    RequestBodies.MaturityRating,
    {},
    {},
    FindingTopicMaturityRatingsCreatePathParams
  >({
    url: '/finding-topic/{id}/maturity-ratings',
    method: 'post',
    ...variables,
    signal,
  });

export const useFindingTopicMaturityRatingsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MaturityRating,
      FindingTopicMaturityRatingsCreateError,
      FindingTopicMaturityRatingsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.MaturityRating,
    FindingTopicMaturityRatingsCreateError,
    FindingTopicMaturityRatingsCreateVariables
  >({
    mutationFn: (variables: FindingTopicMaturityRatingsCreateVariables) =>
      fetchFindingTopicMaturityRatingsCreate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type FindingTopicAssessmentQuestionsListPathParams = {
  topicPk: string;
};

export type FindingTopicAssessmentQuestionsListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingTopicAssessmentQuestionsListResponse =
  Schemas.AssessmentQuestion[];

export type FindingTopicAssessmentQuestionsListVariables = {
  pathParams: FindingTopicAssessmentQuestionsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingTopicAssessmentQuestionsList = (
  variables: FindingTopicAssessmentQuestionsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    FindingTopicAssessmentQuestionsListResponse,
    FindingTopicAssessmentQuestionsListError,
    undefined,
    {},
    {},
    FindingTopicAssessmentQuestionsListPathParams
  >({
    url: '/finding-topic/{topicPk}/assessment-questions',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingTopicAssessmentQuestionsList = <
  TData = FindingTopicAssessmentQuestionsListResponse
>(
  variables: FindingTopicAssessmentQuestionsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      FindingTopicAssessmentQuestionsListResponse,
      FindingTopicAssessmentQuestionsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    FindingTopicAssessmentQuestionsListResponse,
    FindingTopicAssessmentQuestionsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/finding-topic/{topicPk}/assessment-questions',
      operationId: 'findingTopicAssessmentQuestionsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingTopicAssessmentQuestionsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type FindingsCreateError = Fetcher.ErrorWrapper<undefined>;

export type FindingsCreateVariables = {
  body: RequestBodies.Finding;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsCreate = (
  variables: FindingsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Finding,
    FindingsCreateError,
    RequestBodies.Finding,
    {},
    {},
    {}
  >({ url: '/findings/', method: 'post', ...variables, signal });

export const useFindingsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Finding,
      FindingsCreateError,
      FindingsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Finding,
    FindingsCreateError,
    FindingsCreateVariables
  >({
    mutationFn: (variables: FindingsCreateVariables) =>
      fetchFindingsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingsReadPathParams = {
  /**
   * A UUID string identifying this finding.
   *
   * @format uuid
   */
  id: string;
};

export type FindingsReadError = Fetcher.ErrorWrapper<undefined>;

export type FindingsReadVariables = {
  pathParams: FindingsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsRead = (
  variables: FindingsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Finding,
    FindingsReadError,
    undefined,
    {},
    {},
    FindingsReadPathParams
  >({ url: '/findings/{id}', method: 'get', ...variables, signal });

export const useFindingsRead = <TData = Schemas.Finding>(
  variables: FindingsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Finding, FindingsReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Finding, FindingsReadError, TData>({
    queryKey: queryKeyFn({
      path: '/findings/{id}',
      operationId: 'findingsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type FindingsUpdatePathParams = {
  /**
   * A UUID string identifying this finding.
   *
   * @format uuid
   */
  id: string;
};

export type FindingsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type FindingsUpdateVariables = {
  body: RequestBodies.Finding;
  pathParams: FindingsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsUpdate = (
  variables: FindingsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Finding,
    FindingsUpdateError,
    RequestBodies.Finding,
    {},
    {},
    FindingsUpdatePathParams
  >({ url: '/findings/{id}', method: 'put', ...variables, signal });

export const useFindingsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Finding,
      FindingsUpdateError,
      FindingsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Finding,
    FindingsUpdateError,
    FindingsUpdateVariables
  >({
    mutationFn: (variables: FindingsUpdateVariables) =>
      fetchFindingsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingsDeletePathParams = {
  /**
   * A UUID string identifying this finding.
   *
   * @format uuid
   */
  id: string;
};

export type FindingsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type FindingsDeleteVariables = {
  pathParams: FindingsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsDelete = (
  variables: FindingsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingsDeleteError,
    undefined,
    {},
    {},
    FindingsDeletePathParams
  >({ url: '/findings/{id}', method: 'delete', ...variables, signal });

export const useFindingsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      FindingsDeleteError,
      FindingsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    FindingsDeleteError,
    FindingsDeleteVariables
  >({
    mutationFn: (variables: FindingsDeleteVariables) =>
      fetchFindingsDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type FindingsFindingReferencesListPathParams = {
  id: string;
};

export type FindingsFindingReferencesListError =
  Fetcher.ErrorWrapper<undefined>;

export type FindingsFindingReferencesListVariables = {
  pathParams: FindingsFindingReferencesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchFindingsFindingReferencesList = (
  variables: FindingsFindingReferencesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    FindingsFindingReferencesListError,
    undefined,
    {},
    {},
    FindingsFindingReferencesListPathParams
  >({
    url: '/findings/{id}/finding-references',
    method: 'get',
    ...variables,
    signal,
  });

export const useFindingsFindingReferencesList = <TData = undefined>(
  variables: FindingsFindingReferencesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      FindingsFindingReferencesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    FindingsFindingReferencesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/findings/{id}/finding-references',
      operationId: 'findingsFindingReferencesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchFindingsFindingReferencesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type MaturityRatingReadPathParams = {
  /**
   * A UUID string identifying this maturity rating.
   *
   * @format uuid
   */
  id: string;
};

export type MaturityRatingReadError = Fetcher.ErrorWrapper<undefined>;

export type MaturityRatingReadVariables = {
  pathParams: MaturityRatingReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityRatingRead = (
  variables: MaturityRatingReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityRating,
    MaturityRatingReadError,
    undefined,
    {},
    {},
    MaturityRatingReadPathParams
  >({ url: '/maturity-rating/{id}', method: 'get', ...variables, signal });

export const useMaturityRatingRead = <TData = Schemas.MaturityRating>(
  variables: MaturityRatingReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaturityRating,
      MaturityRatingReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaturityRating,
    MaturityRatingReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/maturity-rating/{id}',
      operationId: 'maturityRatingRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMaturityRatingRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type MaturityRatingUpdatePathParams = {
  /**
   * A UUID string identifying this maturity rating.
   *
   * @format uuid
   */
  id: string;
};

export type MaturityRatingUpdateError = Fetcher.ErrorWrapper<undefined>;

export type MaturityRatingUpdateVariables = {
  body: RequestBodies.MaturityRating;
  pathParams: MaturityRatingUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityRatingUpdate = (
  variables: MaturityRatingUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityRating,
    MaturityRatingUpdateError,
    RequestBodies.MaturityRating,
    {},
    {},
    MaturityRatingUpdatePathParams
  >({ url: '/maturity-rating/{id}', method: 'put', ...variables, signal });

export const useMaturityRatingUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MaturityRating,
      MaturityRatingUpdateError,
      MaturityRatingUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.MaturityRating,
    MaturityRatingUpdateError,
    MaturityRatingUpdateVariables
  >({
    mutationFn: (variables: MaturityRatingUpdateVariables) =>
      fetchMaturityRatingUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MaturityViewFindingGroupsTasBenchmarkListPathParams = {
  ident: string;
};

export type MaturityViewFindingGroupsTasBenchmarkListError =
  Fetcher.ErrorWrapper<undefined>;

export type MaturityViewFindingGroupsTasBenchmarkListVariables = {
  pathParams: MaturityViewFindingGroupsTasBenchmarkListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityViewFindingGroupsTasBenchmarkList = (
  variables: MaturityViewFindingGroupsTasBenchmarkListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    MaturityViewFindingGroupsTasBenchmarkListError,
    undefined,
    {},
    {},
    MaturityViewFindingGroupsTasBenchmarkListPathParams
  >({
    url: '/maturity-view/{ident}/finding-groups-tas-benchmark',
    method: 'get',
    ...variables,
    signal,
  });

export const useMaturityViewFindingGroupsTasBenchmarkList = <TData = undefined>(
  variables: MaturityViewFindingGroupsTasBenchmarkListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      MaturityViewFindingGroupsTasBenchmarkListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    MaturityViewFindingGroupsTasBenchmarkListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/maturity-view/{ident}/finding-groups-tas-benchmark',
      operationId: 'maturityViewFindingGroupsTasBenchmarkList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMaturityViewFindingGroupsTasBenchmarkList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type MaturityViewFindingGroupsListPathParams = {
  id: string;
};

export type MaturityViewFindingGroupsListError =
  Fetcher.ErrorWrapper<undefined>;

export type MaturityViewFindingGroupsListResponse = Schemas.FindingGroup[];

export type MaturityViewFindingGroupsListVariables = {
  pathParams: MaturityViewFindingGroupsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityViewFindingGroupsList = (
  variables: MaturityViewFindingGroupsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    MaturityViewFindingGroupsListResponse,
    MaturityViewFindingGroupsListError,
    undefined,
    {},
    {},
    MaturityViewFindingGroupsListPathParams
  >({
    url: '/maturity-view/{id}/finding-groups',
    method: 'get',
    ...variables,
    signal,
  });

export const useMaturityViewFindingGroupsList = <
  TData = MaturityViewFindingGroupsListResponse
>(
  variables: MaturityViewFindingGroupsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      MaturityViewFindingGroupsListResponse,
      MaturityViewFindingGroupsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    MaturityViewFindingGroupsListResponse,
    MaturityViewFindingGroupsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/maturity-view/{id}/finding-groups',
      operationId: 'maturityViewFindingGroupsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMaturityViewFindingGroupsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type MaturityViewMaturityBulkReadPathParams = {
  /**
   * A UUID string identifying this maturity view.
   *
   * @format uuid
   */
  id: string;
};

export type MaturityViewMaturityBulkReadError = Fetcher.ErrorWrapper<undefined>;

export type MaturityViewMaturityBulkReadVariables = {
  pathParams: MaturityViewMaturityBulkReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchMaturityViewMaturityBulkRead = (
  variables: MaturityViewMaturityBulkReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityMaturityView,
    MaturityViewMaturityBulkReadError,
    undefined,
    {},
    {},
    MaturityViewMaturityBulkReadPathParams
  >({
    url: '/maturity-view/{id}/maturity-bulk',
    method: 'get',
    ...variables,
    signal,
  });

export const useMaturityViewMaturityBulkRead = <
  TData = Schemas.MaturityMaturityView
>(
  variables: MaturityViewMaturityBulkReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaturityMaturityView,
      MaturityViewMaturityBulkReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaturityMaturityView,
    MaturityViewMaturityBulkReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/maturity-view/{id}/maturity-bulk',
      operationId: 'maturityViewMaturityBulkRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchMaturityViewMaturityBulkRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type OptionsReadPathParams = {
  /**
   * Option type
   */
  type: 'project_team_role';
};

export type OptionsReadError = Fetcher.ErrorWrapper<undefined>;

export type OptionsReadResponse = Schemas.Option[];

export type OptionsReadVariables = {
  pathParams: OptionsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchOptionsRead = (
  variables: OptionsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    OptionsReadResponse,
    OptionsReadError,
    undefined,
    {},
    {},
    OptionsReadPathParams
  >({ url: '/options/{type}', method: 'get', ...variables, signal });

export const useOptionsRead = <TData = OptionsReadResponse>(
  variables: OptionsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<OptionsReadResponse, OptionsReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<OptionsReadResponse, OptionsReadError, TData>({
    queryKey: queryKeyFn({
      path: '/options/{type}',
      operationId: 'optionsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchOptionsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectCollaboratorsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectCollaboratorsListVariables = OneApiContext['fetcherOptions'];

/**
 * List all users that may collaborate on projects
 */
export const fetchProjectCollaboratorsList = (
  variables: ProjectCollaboratorsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<undefined, ProjectCollaboratorsListError, undefined, {}, {}, {}>({
    url: '/project-collaborators',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * List all users that may collaborate on projects
 */
export const useProjectCollaboratorsList = <TData = undefined>(
  variables: ProjectCollaboratorsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectCollaboratorsListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectCollaboratorsListError, TData>({
    queryKey: queryKeyFn({
      path: '/project-collaborators',
      operationId: 'projectCollaboratorsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectCollaboratorsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectCollaboratorsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectCollaboratorsCreateVariables = {
  body: Schemas.CreateNewUser;
} & OneApiContext['fetcherOptions'];

/**
 * Creates an new user on Auth0 and assigned it to given project.
 */
export const fetchProjectCollaboratorsCreate = (
  variables: ProjectCollaboratorsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectCollaboratorsCreateError,
    Schemas.CreateNewUser,
    {},
    {},
    {}
  >({ url: '/project-collaborators', method: 'post', ...variables, signal });

/**
 * Creates an new user on Auth0 and assigned it to given project.
 */
export const useProjectCollaboratorsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectCollaboratorsCreateError,
      ProjectCollaboratorsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectCollaboratorsCreateError,
    ProjectCollaboratorsCreateVariables
  >({
    mutationFn: (variables: ProjectCollaboratorsCreateVariables) =>
      fetchProjectCollaboratorsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectTeamMembersCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersCreateVariables = {
  body: RequestBodies.ProjectTeamMember;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersCreate = (
  variables: ProjectTeamMembersCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersCreateError,
    RequestBodies.ProjectTeamMember,
    {},
    {},
    {}
  >({ url: '/project-team-members', method: 'post', ...variables, signal });

export const useProjectTeamMembersCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProjectTeamMember,
      ProjectTeamMembersCreateError,
      ProjectTeamMembersCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersCreateError,
    ProjectTeamMembersCreateVariables
  >({
    mutationFn: (variables: ProjectTeamMembersCreateVariables) =>
      fetchProjectTeamMembersCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectTeamMembersReadPathParams = {
  /**
   * A UUID string identifying this project team member.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectTeamMembersReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersReadVariables = {
  pathParams: ProjectTeamMembersReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersRead = (
  variables: ProjectTeamMembersReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersReadError,
    undefined,
    {},
    {},
    ProjectTeamMembersReadPathParams
  >({ url: '/project-team-members/{id}', method: 'get', ...variables, signal });

export const useProjectTeamMembersRead = <TData = Schemas.ProjectTeamMember>(
  variables: ProjectTeamMembersReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProjectTeamMember,
      ProjectTeamMembersReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/project-team-members/{id}',
      operationId: 'projectTeamMembersRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectTeamMembersRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectTeamMembersUpdatePathParams = {
  /**
   * A UUID string identifying this project team member.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectTeamMembersUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersUpdateVariables = {
  body: RequestBodies.ProjectTeamMember;
  pathParams: ProjectTeamMembersUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersUpdate = (
  variables: ProjectTeamMembersUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersUpdateError,
    RequestBodies.ProjectTeamMember,
    {},
    {},
    ProjectTeamMembersUpdatePathParams
  >({ url: '/project-team-members/{id}', method: 'put', ...variables, signal });

export const useProjectTeamMembersUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProjectTeamMember,
      ProjectTeamMembersUpdateError,
      ProjectTeamMembersUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ProjectTeamMember,
    ProjectTeamMembersUpdateError,
    ProjectTeamMembersUpdateVariables
  >({
    mutationFn: (variables: ProjectTeamMembersUpdateVariables) =>
      fetchProjectTeamMembersUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectTeamMembersDeletePathParams = {
  /**
   * A UUID string identifying this project team member.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectTeamMembersDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectTeamMembersDeleteVariables = {
  pathParams: ProjectTeamMembersDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectTeamMembersDelete = (
  variables: ProjectTeamMembersDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectTeamMembersDeleteError,
    undefined,
    {},
    {},
    ProjectTeamMembersDeletePathParams
  >({
    url: '/project-team-members/{id}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectTeamMembersDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectTeamMembersDeleteError,
      ProjectTeamMembersDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectTeamMembersDeleteError,
    ProjectTeamMembersDeleteVariables
  >({
    mutationFn: (variables: ProjectTeamMembersDeleteVariables) =>
      fetchProjectTeamMembersDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsListResponse = Schemas.Project[];

export type ProjectsListVariables = OneApiContext['fetcherOptions'];

/**
 * Returns a list of all the projects the currently authenticated user is a team member of
 */
export const fetchProjectsList = (
  variables: ProjectsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<ProjectsListResponse, ProjectsListError, undefined, {}, {}, {}>({
    url: '/projects',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Returns a list of all the projects the currently authenticated user is a team member of
 */
export const useProjectsList = <TData = ProjectsListResponse>(
  variables: ProjectsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ProjectsListResponse, ProjectsListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<ProjectsListResponse, ProjectsListError, TData>({
    queryKey: queryKeyFn({
      path: '/projects',
      operationId: 'projectsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsCreateVariables = {
  body: Schemas.Project;
} & OneApiContext['fetcherOptions'];

/**
 * Creates a new project and adds the requesting user as project-admin
 */
export const fetchProjectsCreate = (
  variables: ProjectsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Project,
    ProjectsCreateError,
    Schemas.Project,
    {},
    {},
    {}
  >({ url: '/projects', method: 'post', ...variables, signal });

/**
 * Creates a new project and adds the requesting user as project-admin
 */
export const useProjectsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Project,
      ProjectsCreateError,
      ProjectsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.Project,
    ProjectsCreateError,
    ProjectsCreateVariables
  >({
    mutationFn: (variables: ProjectsCreateVariables) =>
      fetchProjectsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsArchiveProjectCreatePathParams = {
  projectPk: string;
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsArchiveProjectCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsArchiveProjectCreateVariables = {
  pathParams: ProjectsArchiveProjectCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Trigger actions that in order to ensure that the project is archived
 */
export const fetchProjectsArchiveProjectCreate = (
  variables: ProjectsArchiveProjectCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsArchiveProjectCreateError,
    undefined,
    {},
    {},
    ProjectsArchiveProjectCreatePathParams
  >({
    url: '/projects/archive-project/{projectPk}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Trigger actions that in order to ensure that the project is archived
 */
export const useProjectsArchiveProjectCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsArchiveProjectCreateError,
      ProjectsArchiveProjectCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsArchiveProjectCreateError,
    ProjectsArchiveProjectCreateVariables
  >({
    mutationFn: (variables: ProjectsArchiveProjectCreateVariables) =>
      fetchProjectsArchiveProjectCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsBulkUpdateMaturityCreatePathParams = {
  projectPk: string;
};

export type ProjectsBulkUpdateMaturityCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsBulkUpdateMaturityCreateResponse = {
  /**
   * IDs of topics to activate
   */
  activate_topics?: string[];
  /**
   * IDs of topics to deactivate
   */
  deactivate_topics?: string[];
  /**
   * IDs of topics to be deleted
   */
  delete_topics?: string[];
  /**
   * IDs of areas to be deleted
   */
  delete_areas?: string[];
  create_topics?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    area_ids?: string[];
  }[];
  update_topics?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
  create_areas?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    group_id?: string;
  }[];
  update_areas?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
};

export type ProjectsBulkUpdateMaturityCreateRequestBody = {
  /**
   * IDs of topics to activate
   */
  activate_topics?: string[];
  /**
   * IDs of topics to deactivate
   */
  deactivate_topics?: string[];
  /**
   * IDs of topics to be deleted
   */
  delete_topics?: string[];
  /**
   * IDs of areas to be deleted
   */
  delete_areas?: string[];
  create_topics?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    area_ids?: string[];
  }[];
  update_topics?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
  create_areas?: {
    id?: string;
    ident?: string;
    title?: string;
    description?: string;
    group_id?: string;
  }[];
  update_areas?: {
    id?: string;
    title?: string;
    description?: string;
  }[];
};

export type ProjectsBulkUpdateMaturityCreateVariables = {
  body?: ProjectsBulkUpdateMaturityCreateRequestBody;
  pathParams: ProjectsBulkUpdateMaturityCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsBulkUpdateMaturityCreate = (
  variables: ProjectsBulkUpdateMaturityCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsBulkUpdateMaturityCreateResponse,
    ProjectsBulkUpdateMaturityCreateError,
    ProjectsBulkUpdateMaturityCreateRequestBody,
    {},
    {},
    ProjectsBulkUpdateMaturityCreatePathParams
  >({
    url: '/projects/bulk-update-maturity/{projectPk}',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsBulkUpdateMaturityCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsBulkUpdateMaturityCreateResponse,
      ProjectsBulkUpdateMaturityCreateError,
      ProjectsBulkUpdateMaturityCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsBulkUpdateMaturityCreateResponse,
    ProjectsBulkUpdateMaturityCreateError,
    ProjectsBulkUpdateMaturityCreateVariables
  >({
    mutationFn: (variables: ProjectsBulkUpdateMaturityCreateVariables) =>
      fetchProjectsBulkUpdateMaturityCreate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsCleanupAccessCreatePathParams = {
  projectPk: string;
};

export type ProjectsCleanupAccessCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsCleanupAccessCreateResponse = {
  blocked_users?: string[];
};

export type ProjectsCleanupAccessCreateVariables = {
  pathParams: ProjectsCleanupAccessCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Clean up external auth0 accesses to the project
 */
export const fetchProjectsCleanupAccessCreate = (
  variables: ProjectsCleanupAccessCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsCleanupAccessCreateResponse,
    ProjectsCleanupAccessCreateError,
    undefined,
    {},
    {},
    ProjectsCleanupAccessCreatePathParams
  >({
    url: '/projects/cleanup-access/{projectPk}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Clean up external auth0 accesses to the project
 */
export const useProjectsCleanupAccessCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsCleanupAccessCreateResponse,
      ProjectsCleanupAccessCreateError,
      ProjectsCleanupAccessCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsCleanupAccessCreateResponse,
    ProjectsCleanupAccessCreateError,
    ProjectsCleanupAccessCreateVariables
  >({
    mutationFn: (variables: ProjectsCleanupAccessCreateVariables) =>
      fetchProjectsCleanupAccessCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsExcelExportReadPathParams = {
  projectPk: string;
};

export type ProjectsExcelExportReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsExcelExportReadVariables = {
  pathParams: ProjectsExcelExportReadPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Export data about Contexts, Repositories, Teams and People as an Excel Workbook.
 */
export const fetchProjectsExcelExportRead = (
  variables: ProjectsExcelExportReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsExcelExportReadError,
    undefined,
    {},
    {},
    ProjectsExcelExportReadPathParams
  >({
    url: '/projects/excel-export/{projectPk}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Export data about Contexts, Repositories, Teams and People as an Excel Workbook.
 */
export const useProjectsExcelExportRead = <TData = undefined>(
  variables: ProjectsExcelExportReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsExcelExportReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsExcelExportReadError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/excel-export/{projectPk}',
      operationId: 'projectsExcelExportRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsExcelExportRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsMetadataOptionsClientIndustriesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsClientIndustriesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsClientIndustriesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsClientIndustriesList = (
  variables: ProjectsMetadataOptionsClientIndustriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsClientIndustriesListResponse,
    ProjectsMetadataOptionsClientIndustriesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/client-industries',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsClientIndustriesList = <
  TData = ProjectsMetadataOptionsClientIndustriesListResponse
>(
  variables: ProjectsMetadataOptionsClientIndustriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsClientIndustriesListResponse,
      ProjectsMetadataOptionsClientIndustriesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsClientIndustriesListResponse,
    ProjectsMetadataOptionsClientIndustriesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/metadata-options/client-industries',
      operationId: 'projectsMetadataOptionsClientIndustriesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsMetadataOptionsClientIndustriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsMetadataOptionsIndustriesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsIndustriesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsIndustriesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsIndustriesList = (
  variables: ProjectsMetadataOptionsIndustriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsIndustriesListResponse,
    ProjectsMetadataOptionsIndustriesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/industries',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsIndustriesList = <
  TData = ProjectsMetadataOptionsIndustriesListResponse
>(
  variables: ProjectsMetadataOptionsIndustriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsIndustriesListResponse,
      ProjectsMetadataOptionsIndustriesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsIndustriesListResponse,
    ProjectsMetadataOptionsIndustriesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/metadata-options/industries',
      operationId: 'projectsMetadataOptionsIndustriesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsMetadataOptionsIndustriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsMetadataOptionsProjectTypesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsProjectTypesListResponse =
  Schemas.ProjectType[];

export type ProjectsMetadataOptionsProjectTypesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsProjectTypesList = (
  variables: ProjectsMetadataOptionsProjectTypesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsProjectTypesListResponse,
    ProjectsMetadataOptionsProjectTypesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/project-types',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsProjectTypesList = <
  TData = ProjectsMetadataOptionsProjectTypesListResponse
>(
  variables: ProjectsMetadataOptionsProjectTypesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsProjectTypesListResponse,
      ProjectsMetadataOptionsProjectTypesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsProjectTypesListResponse,
    ProjectsMetadataOptionsProjectTypesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/metadata-options/project-types',
      operationId: 'projectsMetadataOptionsProjectTypesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsMetadataOptionsProjectTypesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsMetadataOptionsSizesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsSizesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsSizesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsSizesList = (
  variables: ProjectsMetadataOptionsSizesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsSizesListResponse,
    ProjectsMetadataOptionsSizesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/sizes',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsSizesList = <
  TData = ProjectsMetadataOptionsSizesListResponse
>(
  variables: ProjectsMetadataOptionsSizesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsSizesListResponse,
      ProjectsMetadataOptionsSizesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsSizesListResponse,
    ProjectsMetadataOptionsSizesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/metadata-options/sizes',
      operationId: 'projectsMetadataOptionsSizesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsMetadataOptionsSizesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsMetadataOptionsStagesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMetadataOptionsStagesListResponse =
  Schemas.ProjectMetadata[];

export type ProjectsMetadataOptionsStagesListVariables =
  OneApiContext['fetcherOptions'];

export const fetchProjectsMetadataOptionsStagesList = (
  variables: ProjectsMetadataOptionsStagesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMetadataOptionsStagesListResponse,
    ProjectsMetadataOptionsStagesListError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/projects/metadata-options/stages',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMetadataOptionsStagesList = <
  TData = ProjectsMetadataOptionsStagesListResponse
>(
  variables: ProjectsMetadataOptionsStagesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMetadataOptionsStagesListResponse,
      ProjectsMetadataOptionsStagesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMetadataOptionsStagesListResponse,
    ProjectsMetadataOptionsStagesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/metadata-options/stages',
      operationId: 'projectsMetadataOptionsStagesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsMetadataOptionsStagesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsReadPathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsReadVariables = {
  pathParams: ProjectsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRead = (
  variables: ProjectsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectDetail,
    ProjectsReadError,
    undefined,
    {},
    {},
    ProjectsReadPathParams
  >({ url: '/projects/{id}', method: 'get', ...variables, signal });

export const useProjectsRead = <TData = Schemas.ProjectDetail>(
  variables: ProjectsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ProjectDetail, ProjectsReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.ProjectDetail, ProjectsReadError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{id}',
      operationId: 'projectsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsUpdatePathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsUpdateVariables = {
  body: Schemas.ProjectDetail;
  pathParams: ProjectsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsUpdate = (
  variables: ProjectsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectDetail,
    ProjectsUpdateError,
    Schemas.ProjectDetail,
    {},
    {},
    ProjectsUpdatePathParams
  >({ url: '/projects/{id}', method: 'put', ...variables, signal });

export const useProjectsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProjectDetail,
      ProjectsUpdateError,
      ProjectsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ProjectDetail,
    ProjectsUpdateError,
    ProjectsUpdateVariables
  >({
    mutationFn: (variables: ProjectsUpdateVariables) =>
      fetchProjectsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsDeletePathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsDeleteVariables = {
  pathParams: ProjectsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsDelete = (
  variables: ProjectsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsDeleteError,
    undefined,
    {},
    {},
    ProjectsDeletePathParams
  >({ url: '/projects/{id}', method: 'delete', ...variables, signal });

export const useProjectsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsDeleteError,
      ProjectsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsDeleteError,
    ProjectsDeleteVariables
  >({
    mutationFn: (variables: ProjectsDeleteVariables) =>
      fetchProjectsDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsDashboardUrlListPathParams = {
  id: string;
};

export type ProjectsDashboardUrlListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsDashboardUrlListVariables = {
  pathParams: ProjectsDashboardUrlListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsDashboardUrlList = (
  variables: ProjectsDashboardUrlListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsDashboardUrlListError,
    undefined,
    {},
    {},
    ProjectsDashboardUrlListPathParams
  >({
    url: '/projects/{id}/dashboard-url',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsDashboardUrlList = <TData = undefined>(
  variables: ProjectsDashboardUrlListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsDashboardUrlListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsDashboardUrlListError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{id}/dashboard-url',
      operationId: 'projectsDashboardUrlList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsDashboardUrlList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsDirectoryCreatePathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsDirectoryCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsDirectoryCreateVariables = {
  pathParams: ProjectsDirectoryCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Request body example:
 *     {"path": "some/sub/path/"}
 */
export const fetchProjectsDirectoryCreate = (
  variables: ProjectsDirectoryCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsDirectoryCreateError,
    undefined,
    {},
    {},
    ProjectsDirectoryCreatePathParams
  >({ url: '/projects/{id}/directory', method: 'post', ...variables, signal });

/**
 * Request body example:
 *     {"path": "some/sub/path/"}
 */
export const useProjectsDirectoryCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsDirectoryCreateError,
      ProjectsDirectoryCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsDirectoryCreateError,
    ProjectsDirectoryCreateVariables
  >({
    mutationFn: (variables: ProjectsDirectoryCreateVariables) =>
      fetchProjectsDirectoryCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsExportCreatePathParams = {
  id: string;
};

export type ProjectsExportCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsExportCreateVariables = {
  pathParams: ProjectsExportCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsExportCreate = (
  variables: ProjectsExportCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Record<string, any>,
    ProjectsExportCreateError,
    undefined,
    {},
    {},
    ProjectsExportCreatePathParams
  >({ url: '/projects/{id}/export', method: 'post', ...variables, signal });

export const useProjectsExportCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ProjectsExportCreateError,
      ProjectsExportCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ProjectsExportCreateError,
    ProjectsExportCreateVariables
  >({
    mutationFn: (variables: ProjectsExportCreateVariables) =>
      fetchProjectsExportCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsExportMaturityListPathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsExportMaturityListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsExportMaturityListVariables = {
  pathParams: ProjectsExportMaturityListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsExportMaturityList = (
  variables: ProjectsExportMaturityListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsExportMaturityListError,
    undefined,
    {},
    {},
    ProjectsExportMaturityListPathParams
  >({
    url: '/projects/{id}/export-maturity',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsExportMaturityList = <TData = undefined>(
  variables: ProjectsExportMaturityListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      ProjectsExportMaturityListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsExportMaturityListError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/projects/{id}/export-maturity',
        operationId: 'projectsExportMaturityList',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchProjectsExportMaturityList(
          { ...fetcherOptions, ...variables },
          signal
        ),
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsFilesListPathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsFilesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFilesListResponse = Schemas.FilesItemResponse[];

export type ProjectsFilesListVariables = {
  pathParams: ProjectsFilesListPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * retrieves the given project's files and directories. A filter can be passed optionally as request body (which
 *     is also the reason why this API expects POST and not GET).
 *
 *     Request body example:
 *     {"filter":
 *         {"path": "some/sub/path/"}
 *     }
 */
export const fetchProjectsFilesList = (
  variables: ProjectsFilesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFilesListResponse,
    ProjectsFilesListError,
    undefined,
    {},
    {},
    ProjectsFilesListPathParams
  >({ url: '/projects/{id}/files', method: 'post', ...variables, signal });

/**
 * retrieves the given project's files and directories. A filter can be passed optionally as request body (which
 *     is also the reason why this API expects POST and not GET).
 *
 *     Request body example:
 *     {"filter":
 *         {"path": "some/sub/path/"}
 *     }
 */
export const useProjectsFilesList = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsFilesListResponse,
      ProjectsFilesListError,
      ProjectsFilesListVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsFilesListResponse,
    ProjectsFilesListError,
    ProjectsFilesListVariables
  >({
    mutationFn: (variables: ProjectsFilesListVariables) =>
      fetchProjectsFilesList({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsFilesReadPathParams = {
  /**
   * Project ID
   */
  id: string;
  /**
   * urlsafe-base64 encoded file path
   */
  pathB64: string;
};

export type ProjectsFilesReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFilesReadVariables = {
  pathParams: ProjectsFilesReadPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * responds with the content of the requested file
 */
export const fetchProjectsFilesRead = (
  variables: ProjectsFilesReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFilesReadError,
    undefined,
    {},
    {},
    ProjectsFilesReadPathParams
  >({
    url: '/projects/{id}/files/{pathB64}',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * responds with the content of the requested file
 */
export const useProjectsFilesRead = <TData = undefined>(
  variables: ProjectsFilesReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsFilesReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsFilesReadError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{id}/files/{pathB64}',
      operationId: 'projectsFilesRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFilesRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFilesCreatePathParams = {
  /**
   * Project ID
   */
  id: string;
  /**
   * urlsafe-base64 encoded file path
   */
  pathB64: string;
};

export type ProjectsFilesCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFilesCreateVariables = {
  pathParams: ProjectsFilesCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * creates new file
 */
export const fetchProjectsFilesCreate = (
  variables: ProjectsFilesCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFilesCreateError,
    undefined,
    {},
    {},
    ProjectsFilesCreatePathParams
  >({
    url: '/projects/{id}/files/{pathB64}',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * creates new file
 */
export const useProjectsFilesCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsFilesCreateError,
      ProjectsFilesCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsFilesCreateError,
    ProjectsFilesCreateVariables
  >({
    mutationFn: (variables: ProjectsFilesCreateVariables) =>
      fetchProjectsFilesCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsFindingAreasListPathParams = {
  id: string;
};

export type ProjectsFindingAreasListQueryParams = {
  /**
   * Shows only FindingAreas with Findings
   */
  onlyWithFindings?: boolean;
};

export type ProjectsFindingAreasListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingAreasListVariables = {
  pathParams: ProjectsFindingAreasListPathParams;
  queryParams?: ProjectsFindingAreasListQueryParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingAreasList = (
  variables: ProjectsFindingAreasListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFindingAreasListError,
    undefined,
    {},
    ProjectsFindingAreasListQueryParams,
    ProjectsFindingAreasListPathParams
  >({
    url: '/projects/{id}/finding-areas',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingAreasList = <TData = undefined>(
  variables: ProjectsFindingAreasListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsFindingAreasListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsFindingAreasListError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{id}/finding-areas',
      operationId: 'projectsFindingAreasList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFindingAreasList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFindingGroupsListPathParams = {
  id: string;
};

export type ProjectsFindingGroupsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingGroupsListResponse = Schemas.FindingGroup[];

export type ProjectsFindingGroupsListVariables = {
  pathParams: ProjectsFindingGroupsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingGroupsList = (
  variables: ProjectsFindingGroupsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingGroupsListResponse,
    ProjectsFindingGroupsListError,
    undefined,
    {},
    {},
    ProjectsFindingGroupsListPathParams
  >({
    url: '/projects/{id}/finding-groups',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingGroupsList = <
  TData = ProjectsFindingGroupsListResponse
>(
  variables: ProjectsFindingGroupsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingGroupsListResponse,
      ProjectsFindingGroupsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingGroupsListResponse,
    ProjectsFindingGroupsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/finding-groups',
      operationId: 'projectsFindingGroupsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFindingGroupsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFindingGroupsRatingListPathParams = {
  id: string;
};

export type ProjectsFindingGroupsRatingListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingGroupsRatingListVariables = {
  pathParams: ProjectsFindingGroupsRatingListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingGroupsRatingList = (
  variables: ProjectsFindingGroupsRatingListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFindingGroupsRatingListError,
    undefined,
    {},
    {},
    ProjectsFindingGroupsRatingListPathParams
  >({
    url: '/projects/{id}/finding-groups/rating',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingGroupsRatingList = <TData = undefined>(
  variables: ProjectsFindingGroupsRatingListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      ProjectsFindingGroupsRatingListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    ProjectsFindingGroupsRatingListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/finding-groups/rating',
      operationId: 'projectsFindingGroupsRatingList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFindingGroupsRatingList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFindingsListPathParams = {
  id: string;
};

export type ProjectsFindingsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingsListResponse = Schemas.Finding[];

export type ProjectsFindingsListVariables = {
  pathParams: ProjectsFindingsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingsList = (
  variables: ProjectsFindingsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingsListResponse,
    ProjectsFindingsListError,
    undefined,
    {},
    {},
    ProjectsFindingsListPathParams
  >({ url: '/projects/{id}/findings', method: 'get', ...variables, signal });

export const useProjectsFindingsList = <TData = ProjectsFindingsListResponse>(
  variables: ProjectsFindingsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingsListResponse,
      ProjectsFindingsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingsListResponse,
    ProjectsFindingsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/findings',
      operationId: 'projectsFindingsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFindingsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFindingsStrengthsListPathParams = {
  id: string;
};

export type ProjectsFindingsStrengthsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingsStrengthsListResponse = Schemas.Finding[];

export type ProjectsFindingsStrengthsListVariables = {
  pathParams: ProjectsFindingsStrengthsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingsStrengthsList = (
  variables: ProjectsFindingsStrengthsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingsStrengthsListResponse,
    ProjectsFindingsStrengthsListError,
    undefined,
    {},
    {},
    ProjectsFindingsStrengthsListPathParams
  >({
    url: '/projects/{id}/findings/strengths',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingsStrengthsList = <
  TData = ProjectsFindingsStrengthsListResponse
>(
  variables: ProjectsFindingsStrengthsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingsStrengthsListResponse,
      ProjectsFindingsStrengthsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingsStrengthsListResponse,
    ProjectsFindingsStrengthsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/findings/strengths',
      operationId: 'projectsFindingsStrengthsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFindingsStrengthsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFindingsWeaknessesListPathParams = {
  id: string;
};

export type ProjectsFindingsWeaknessesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFindingsWeaknessesListResponse = Schemas.Finding[];

export type ProjectsFindingsWeaknessesListVariables = {
  pathParams: ProjectsFindingsWeaknessesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFindingsWeaknessesList = (
  variables: ProjectsFindingsWeaknessesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFindingsWeaknessesListResponse,
    ProjectsFindingsWeaknessesListError,
    undefined,
    {},
    {},
    ProjectsFindingsWeaknessesListPathParams
  >({
    url: '/projects/{id}/findings/weaknesses',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFindingsWeaknessesList = <
  TData = ProjectsFindingsWeaknessesListResponse
>(
  variables: ProjectsFindingsWeaknessesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFindingsWeaknessesListResponse,
      ProjectsFindingsWeaknessesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFindingsWeaknessesListResponse,
    ProjectsFindingsWeaknessesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/findings/weaknesses',
      operationId: 'projectsFindingsWeaknessesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFindingsWeaknessesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsImportCreatePathParams = {
  id: string;
};

export type ProjectsImportCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsImportCreateVariables = {
  body?: Record<string, any>;
  pathParams: ProjectsImportCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsImportCreate = (
  variables: ProjectsImportCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Record<string, any>,
    ProjectsImportCreateError,
    Record<string, any>,
    {},
    {},
    ProjectsImportCreatePathParams
  >({ url: '/projects/{id}/import', method: 'post', ...variables, signal });

export const useProjectsImportCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      ProjectsImportCreateError,
      ProjectsImportCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Record<string, any>,
    ProjectsImportCreateError,
    ProjectsImportCreateVariables
  >({
    mutationFn: (variables: ProjectsImportCreateVariables) =>
      fetchProjectsImportCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsMaturityBulkReadPathParams = {
  /**
   * A UUID string identifying this project.
   *
   * @format uuid
   */
  id: string;
};

export type ProjectsMaturityBulkReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsMaturityBulkReadVariables = {
  pathParams: ProjectsMaturityBulkReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsMaturityBulkRead = (
  variables: ProjectsMaturityBulkReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.MaturityProject,
    ProjectsMaturityBulkReadError,
    undefined,
    {},
    {},
    ProjectsMaturityBulkReadPathParams
  >({
    url: '/projects/{id}/maturity-bulk',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMaturityBulkRead = <TData = Schemas.MaturityProject>(
  variables: ProjectsMaturityBulkReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MaturityProject,
      ProjectsMaturityBulkReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.MaturityProject,
    ProjectsMaturityBulkReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/maturity-bulk',
      operationId: 'projectsMaturityBulkRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsMaturityBulkRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsMaturityViewsListPathParams = {
  id: string;
};

export type ProjectsMaturityViewsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsMaturityViewsListResponse = Schemas.MaturityView[];

export type ProjectsMaturityViewsListVariables = {
  pathParams: ProjectsMaturityViewsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsMaturityViewsList = (
  variables: ProjectsMaturityViewsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMaturityViewsListResponse,
    ProjectsMaturityViewsListError,
    undefined,
    {},
    {},
    ProjectsMaturityViewsListPathParams
  >({
    url: '/projects/{id}/maturity-views',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsMaturityViewsList = <
  TData = ProjectsMaturityViewsListResponse
>(
  variables: ProjectsMaturityViewsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsMaturityViewsListResponse,
      ProjectsMaturityViewsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsMaturityViewsListResponse,
    ProjectsMaturityViewsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/maturity-views',
      operationId: 'projectsMaturityViewsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsMaturityViewsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsRelevantQuestionsListPathParams = {
  id: string;
};

export type ProjectsRelevantQuestionsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsRelevantQuestionsListResponse = string[];

export type ProjectsRelevantQuestionsListVariables = {
  pathParams: ProjectsRelevantQuestionsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRelevantQuestionsList = (
  variables: ProjectsRelevantQuestionsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsRelevantQuestionsListResponse,
    ProjectsRelevantQuestionsListError,
    undefined,
    {},
    {},
    ProjectsRelevantQuestionsListPathParams
  >({
    url: '/projects/{id}/relevant-questions',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsRelevantQuestionsList = <
  TData = ProjectsRelevantQuestionsListResponse
>(
  variables: ProjectsRelevantQuestionsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsRelevantQuestionsListResponse,
      ProjectsRelevantQuestionsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsRelevantQuestionsListResponse,
    ProjectsRelevantQuestionsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/relevant-questions',
      operationId: 'projectsRelevantQuestionsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsRelevantQuestionsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsReportingChartsListPathParams = {
  id: string;
};

export type ProjectsReportingChartsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsReportingChartsListVariables = {
  pathParams: ProjectsReportingChartsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsReportingChartsList = (
  variables: ProjectsReportingChartsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsReportingChartsListError,
    undefined,
    {},
    {},
    ProjectsReportingChartsListPathParams
  >({
    url: '/projects/{id}/reporting-charts',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsReportingChartsList = <TData = undefined>(
  variables: ProjectsReportingChartsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      ProjectsReportingChartsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    undefined,
    ProjectsReportingChartsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/reporting-charts',
      operationId: 'projectsReportingChartsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsReportingChartsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsServiceModulesListPathParams = {
  id: string;
};

export type ProjectsServiceModulesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsServiceModulesListResponse = Schemas.ServiceModule[];

export type ProjectsServiceModulesListVariables = {
  pathParams: ProjectsServiceModulesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsServiceModulesList = (
  variables: ProjectsServiceModulesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsServiceModulesListResponse,
    ProjectsServiceModulesListError,
    undefined,
    {},
    {},
    ProjectsServiceModulesListPathParams
  >({
    url: '/projects/{id}/service-modules',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsServiceModulesList = <
  TData = ProjectsServiceModulesListResponse
>(
  variables: ProjectsServiceModulesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsServiceModulesListResponse,
      ProjectsServiceModulesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsServiceModulesListResponse,
    ProjectsServiceModulesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/service-modules',
      operationId: 'projectsServiceModulesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsServiceModulesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTasSlidesListPathParams = {
  id: string;
};

export type ProjectsTasSlidesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlidesListResponse = Schemas.TasRatingSlide[];

export type ProjectsTasSlidesListVariables = {
  pathParams: ProjectsTasSlidesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlidesList = (
  variables: ProjectsTasSlidesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTasSlidesListResponse,
    ProjectsTasSlidesListError,
    undefined,
    {},
    {},
    ProjectsTasSlidesListPathParams
  >({ url: '/projects/{id}/tas-slides', method: 'get', ...variables, signal });

export const useProjectsTasSlidesList = <TData = ProjectsTasSlidesListResponse>(
  variables: ProjectsTasSlidesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTasSlidesListResponse,
      ProjectsTasSlidesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTasSlidesListResponse,
    ProjectsTasSlidesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/tas-slides',
      operationId: 'projectsTasSlidesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTasSlidesList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTasSlidesCreatePathParams = {
  id: string;
};

export type ProjectsTasSlidesCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlidesCreateVariables = {
  body: RequestBodies.TasRatingSlide;
  pathParams: ProjectsTasSlidesCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlidesCreate = (
  variables: ProjectsTasSlidesCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TasRatingSlide,
    ProjectsTasSlidesCreateError,
    RequestBodies.TasRatingSlide,
    {},
    {},
    ProjectsTasSlidesCreatePathParams
  >({ url: '/projects/{id}/tas-slides', method: 'post', ...variables, signal });

export const useProjectsTasSlidesCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TasRatingSlide,
      ProjectsTasSlidesCreateError,
      ProjectsTasSlidesCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.TasRatingSlide,
    ProjectsTasSlidesCreateError,
    ProjectsTasSlidesCreateVariables
  >({
    mutationFn: (variables: ProjectsTasSlidesCreateVariables) =>
      fetchProjectsTasSlidesCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTasSlidesBulkCreatePathParams = {
  id: string;
};

export type ProjectsTasSlidesBulkCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlidesBulkCreateResponse = Schemas.TasRatingSlideBulk[];

export type ProjectsTasSlidesBulkCreateRequestBody =
  Schemas.TasRatingSlideBulk[];

export type ProjectsTasSlidesBulkCreateVariables = {
  body?: ProjectsTasSlidesBulkCreateRequestBody;
  pathParams: ProjectsTasSlidesBulkCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlidesBulkCreate = (
  variables: ProjectsTasSlidesBulkCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTasSlidesBulkCreateResponse,
    ProjectsTasSlidesBulkCreateError,
    ProjectsTasSlidesBulkCreateRequestBody,
    {},
    {},
    ProjectsTasSlidesBulkCreatePathParams
  >({
    url: '/projects/{id}/tas-slides-bulk',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsTasSlidesBulkCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsTasSlidesBulkCreateResponse,
      ProjectsTasSlidesBulkCreateError,
      ProjectsTasSlidesBulkCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsTasSlidesBulkCreateResponse,
    ProjectsTasSlidesBulkCreateError,
    ProjectsTasSlidesBulkCreateVariables
  >({
    mutationFn: (variables: ProjectsTasSlidesBulkCreateVariables) =>
      fetchProjectsTasSlidesBulkCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTasSlidesReadPathParams = {
  id: string;
  tasSlidePk: string;
};

export type ProjectsTasSlidesReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlidesReadVariables = {
  pathParams: ProjectsTasSlidesReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlidesRead = (
  variables: ProjectsTasSlidesReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TasRatingSlide,
    ProjectsTasSlidesReadError,
    undefined,
    {},
    {},
    ProjectsTasSlidesReadPathParams
  >({
    url: '/projects/{id}/tas-slides/{tasSlidePk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTasSlidesRead = <TData = Schemas.TasRatingSlide>(
  variables: ProjectsTasSlidesReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TasRatingSlide,
      ProjectsTasSlidesReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.TasRatingSlide,
    ProjectsTasSlidesReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/tas-slides/{tasSlidePk}',
      operationId: 'projectsTasSlidesRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTasSlidesRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTasSlidesUpdatePathParams = {
  id: string;
  tasSlidePk: string;
};

export type ProjectsTasSlidesUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlidesUpdateVariables = {
  body: RequestBodies.TasRatingSlide;
  pathParams: ProjectsTasSlidesUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlidesUpdate = (
  variables: ProjectsTasSlidesUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TasRatingSlide,
    ProjectsTasSlidesUpdateError,
    RequestBodies.TasRatingSlide,
    {},
    {},
    ProjectsTasSlidesUpdatePathParams
  >({
    url: '/projects/{id}/tas-slides/{tasSlidePk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTasSlidesUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TasRatingSlide,
      ProjectsTasSlidesUpdateError,
      ProjectsTasSlidesUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.TasRatingSlide,
    ProjectsTasSlidesUpdateError,
    ProjectsTasSlidesUpdateVariables
  >({
    mutationFn: (variables: ProjectsTasSlidesUpdateVariables) =>
      fetchProjectsTasSlidesUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTasSlidesDeletePathParams = {
  id: string;
  tasSlidePk: string;
};

export type ProjectsTasSlidesDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlidesDeleteVariables = {
  pathParams: ProjectsTasSlidesDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlidesDelete = (
  variables: ProjectsTasSlidesDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTasSlidesDeleteError,
    undefined,
    {},
    {},
    ProjectsTasSlidesDeletePathParams
  >({
    url: '/projects/{id}/tas-slides/{tasSlidePk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsTasSlidesDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTasSlidesDeleteError,
      ProjectsTasSlidesDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTasSlidesDeleteError,
    ProjectsTasSlidesDeleteVariables
  >({
    mutationFn: (variables: ProjectsTasSlidesDeleteVariables) =>
      fetchProjectsTasSlidesDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTasSlotsListPathParams = {
  id: string;
};

export type ProjectsTasSlotsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlotsListResponse = Schemas.TasRatingSlot[];

export type ProjectsTasSlotsListVariables = {
  pathParams: ProjectsTasSlotsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlotsList = (
  variables: ProjectsTasSlotsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTasSlotsListResponse,
    ProjectsTasSlotsListError,
    undefined,
    {},
    {},
    ProjectsTasSlotsListPathParams
  >({ url: '/projects/{id}/tas-slots', method: 'get', ...variables, signal });

export const useProjectsTasSlotsList = <TData = ProjectsTasSlotsListResponse>(
  variables: ProjectsTasSlotsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTasSlotsListResponse,
      ProjectsTasSlotsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTasSlotsListResponse,
    ProjectsTasSlotsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/tas-slots',
      operationId: 'projectsTasSlotsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTasSlotsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTasSlotsCreatePathParams = {
  id: string;
};

export type ProjectsTasSlotsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlotsCreateVariables = {
  body: RequestBodies.TasRatingSlot;
  pathParams: ProjectsTasSlotsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlotsCreate = (
  variables: ProjectsTasSlotsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TasRatingSlot,
    ProjectsTasSlotsCreateError,
    RequestBodies.TasRatingSlot,
    {},
    {},
    ProjectsTasSlotsCreatePathParams
  >({ url: '/projects/{id}/tas-slots', method: 'post', ...variables, signal });

export const useProjectsTasSlotsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TasRatingSlot,
      ProjectsTasSlotsCreateError,
      ProjectsTasSlotsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.TasRatingSlot,
    ProjectsTasSlotsCreateError,
    ProjectsTasSlotsCreateVariables
  >({
    mutationFn: (variables: ProjectsTasSlotsCreateVariables) =>
      fetchProjectsTasSlotsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTasSlotsReadPathParams = {
  id: string;
  tasSlotPk: string;
};

export type ProjectsTasSlotsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlotsReadResponse = {
  id: string;
  finding_group: string;
  order: number;
  comment?: string;
  tas_slide: string;
  scores?: {
    tas_score?: number;
    positive_perc?: number;
    neutral_perc?: number;
    negative_perc?: number;
  };
};

export type ProjectsTasSlotsReadVariables = {
  pathParams: ProjectsTasSlotsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlotsRead = (
  variables: ProjectsTasSlotsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTasSlotsReadResponse,
    ProjectsTasSlotsReadError,
    undefined,
    {},
    {},
    ProjectsTasSlotsReadPathParams
  >({
    url: '/projects/{id}/tas-slots/{tasSlotPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTasSlotsRead = <TData = ProjectsTasSlotsReadResponse>(
  variables: ProjectsTasSlotsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTasSlotsReadResponse,
      ProjectsTasSlotsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTasSlotsReadResponse,
    ProjectsTasSlotsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/tas-slots/{tasSlotPk}',
      operationId: 'projectsTasSlotsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTasSlotsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTasSlotsUpdatePathParams = {
  id: string;
  tasSlotPk: string;
};

export type ProjectsTasSlotsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlotsUpdateVariables = {
  body: RequestBodies.TasRatingSlot;
  pathParams: ProjectsTasSlotsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlotsUpdate = (
  variables: ProjectsTasSlotsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TasRatingSlot,
    ProjectsTasSlotsUpdateError,
    RequestBodies.TasRatingSlot,
    {},
    {},
    ProjectsTasSlotsUpdatePathParams
  >({
    url: '/projects/{id}/tas-slots/{tasSlotPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTasSlotsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TasRatingSlot,
      ProjectsTasSlotsUpdateError,
      ProjectsTasSlotsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.TasRatingSlot,
    ProjectsTasSlotsUpdateError,
    ProjectsTasSlotsUpdateVariables
  >({
    mutationFn: (variables: ProjectsTasSlotsUpdateVariables) =>
      fetchProjectsTasSlotsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTasSlotsDeletePathParams = {
  id: string;
  tasSlotPk: string;
};

export type ProjectsTasSlotsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTasSlotsDeleteVariables = {
  pathParams: ProjectsTasSlotsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTasSlotsDelete = (
  variables: ProjectsTasSlotsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTasSlotsDeleteError,
    undefined,
    {},
    {},
    ProjectsTasSlotsDeletePathParams
  >({
    url: '/projects/{id}/tas-slots/{tasSlotPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsTasSlotsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTasSlotsDeleteError,
      ProjectsTasSlotsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTasSlotsDeleteError,
    ProjectsTasSlotsDeleteVariables
  >({
    mutationFn: (variables: ProjectsTasSlotsDeleteVariables) =>
      fetchProjectsTasSlotsDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTeamMembersListPathParams = {
  id: string;
};

export type ProjectsTeamMembersListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamMembersListResponse =
  Schemas.ProjectTeamMemberWithUser[];

export type ProjectsTeamMembersListVariables = {
  pathParams: ProjectsTeamMembersListPathParams;
} & OneApiContext['fetcherOptions'];

/**
 * List all existing team-members with auth0 name and email by project id.
 */
export const fetchProjectsTeamMembersList = (
  variables: ProjectsTeamMembersListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTeamMembersListResponse,
    ProjectsTeamMembersListError,
    undefined,
    {},
    {},
    ProjectsTeamMembersListPathParams
  >({
    url: '/projects/{id}/team-members',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * List all existing team-members with auth0 name and email by project id.
 */
export const useProjectsTeamMembersList = <
  TData = ProjectsTeamMembersListResponse
>(
  variables: ProjectsTeamMembersListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTeamMembersListResponse,
      ProjectsTeamMembersListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTeamMembersListResponse,
    ProjectsTeamMembersListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/team-members',
      operationId: 'projectsTeamMembersList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTeamMembersList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsToolsStatusReadPathParams = {
  id: string;
};

export type ProjectsToolsStatusReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsToolsStatusReadVariables = {
  pathParams: ProjectsToolsStatusReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsToolsStatusRead = (
  variables: ProjectsToolsStatusReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectToolsStatus,
    ProjectsToolsStatusReadError,
    undefined,
    {},
    {},
    ProjectsToolsStatusReadPathParams
  >({
    url: '/projects/{id}/tools-status',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsToolsStatusRead = <TData = Schemas.ProjectToolsStatus>(
  variables: ProjectsToolsStatusReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ProjectToolsStatus,
      ProjectsToolsStatusReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.ProjectToolsStatus,
    ProjectsToolsStatusReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{id}/tools-status',
      operationId: 'projectsToolsStatusRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsToolsStatusRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsToolsStatusUpdatePathParams = {
  id: string;
};

export type ProjectsToolsStatusUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsToolsStatusUpdateVariables = {
  body: Schemas.ProjectToolsStatus;
  pathParams: ProjectsToolsStatusUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsToolsStatusUpdate = (
  variables: ProjectsToolsStatusUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ProjectToolsStatus,
    ProjectsToolsStatusUpdateError,
    Schemas.ProjectToolsStatus,
    {},
    {},
    ProjectsToolsStatusUpdatePathParams
  >({
    url: '/projects/{id}/tools-status',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsToolsStatusUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProjectToolsStatus,
      ProjectsToolsStatusUpdateError,
      ProjectsToolsStatusUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ProjectToolsStatus,
    ProjectsToolsStatusUpdateError,
    ProjectsToolsStatusUpdateVariables
  >({
    mutationFn: (variables: ProjectsToolsStatusUpdateVariables) =>
      fetchProjectsToolsStatusUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsUpdateMaturityGridUpdatePathParams = {
  /**
   * Project ID
   */
  id: string;
};

export type ProjectsUpdateMaturityGridUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsUpdateMaturityGridUpdateVariables = {
  pathParams: ProjectsUpdateMaturityGridUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsUpdateMaturityGridUpdate = (
  variables: ProjectsUpdateMaturityGridUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsUpdateMaturityGridUpdateError,
    undefined,
    {},
    {},
    ProjectsUpdateMaturityGridUpdatePathParams
  >({
    url: '/projects/{id}/update-maturity-grid',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsUpdateMaturityGridUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsUpdateMaturityGridUpdateError,
      ProjectsUpdateMaturityGridUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsUpdateMaturityGridUpdateError,
    ProjectsUpdateMaturityGridUpdateVariables
  >({
    mutationFn: (variables: ProjectsUpdateMaturityGridUpdateVariables) =>
      fetchProjectsUpdateMaturityGridUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsClientSpecificQuestionsListPathParams = {
  projectPk: string;
};

export type ProjectsClientSpecificQuestionsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsClientSpecificQuestionsListResponse =
  Schemas.ClientSpecificQuestion[];

export type ProjectsClientSpecificQuestionsListVariables = {
  pathParams: ProjectsClientSpecificQuestionsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsClientSpecificQuestionsList = (
  variables: ProjectsClientSpecificQuestionsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsClientSpecificQuestionsListResponse,
    ProjectsClientSpecificQuestionsListError,
    undefined,
    {},
    {},
    ProjectsClientSpecificQuestionsListPathParams
  >({
    url: '/projects/{projectPk}/client-specific-questions',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsClientSpecificQuestionsList = <
  TData = ProjectsClientSpecificQuestionsListResponse
>(
  variables: ProjectsClientSpecificQuestionsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsClientSpecificQuestionsListResponse,
      ProjectsClientSpecificQuestionsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsClientSpecificQuestionsListResponse,
    ProjectsClientSpecificQuestionsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/client-specific-questions',
      operationId: 'projectsClientSpecificQuestionsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsClientSpecificQuestionsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsClientSpecificQuestionsCreatePathParams = {
  projectPk: string;
};

export type ProjectsClientSpecificQuestionsCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsClientSpecificQuestionsCreateVariables = {
  body: RequestBodies.ClientSpecificQuestion;
  pathParams: ProjectsClientSpecificQuestionsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsClientSpecificQuestionsCreate = (
  variables: ProjectsClientSpecificQuestionsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ClientSpecificQuestion,
    ProjectsClientSpecificQuestionsCreateError,
    RequestBodies.ClientSpecificQuestion,
    {},
    {},
    ProjectsClientSpecificQuestionsCreatePathParams
  >({
    url: '/projects/{projectPk}/client-specific-questions',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsClientSpecificQuestionsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ClientSpecificQuestion,
      ProjectsClientSpecificQuestionsCreateError,
      ProjectsClientSpecificQuestionsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ClientSpecificQuestion,
    ProjectsClientSpecificQuestionsCreateError,
    ProjectsClientSpecificQuestionsCreateVariables
  >({
    mutationFn: (variables: ProjectsClientSpecificQuestionsCreateVariables) =>
      fetchProjectsClientSpecificQuestionsCreate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsClientSpecificQuestionsReadPathParams = {
  projectPk: string;
  questionPk: string;
};

export type ProjectsClientSpecificQuestionsReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsClientSpecificQuestionsReadVariables = {
  pathParams: ProjectsClientSpecificQuestionsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsClientSpecificQuestionsRead = (
  variables: ProjectsClientSpecificQuestionsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ClientSpecificQuestion,
    ProjectsClientSpecificQuestionsReadError,
    undefined,
    {},
    {},
    ProjectsClientSpecificQuestionsReadPathParams
  >({
    url: '/projects/{projectPk}/client-specific-questions/{questionPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsClientSpecificQuestionsRead = <
  TData = Schemas.ClientSpecificQuestion
>(
  variables: ProjectsClientSpecificQuestionsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ClientSpecificQuestion,
      ProjectsClientSpecificQuestionsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.ClientSpecificQuestion,
    ProjectsClientSpecificQuestionsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/client-specific-questions/{questionPk}',
      operationId: 'projectsClientSpecificQuestionsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsClientSpecificQuestionsRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsClientSpecificQuestionsUpdatePathParams = {
  projectPk: string;
  questionPk: string;
};

export type ProjectsClientSpecificQuestionsUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsClientSpecificQuestionsUpdateVariables = {
  body: RequestBodies.ClientSpecificQuestion;
  pathParams: ProjectsClientSpecificQuestionsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsClientSpecificQuestionsUpdate = (
  variables: ProjectsClientSpecificQuestionsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.ClientSpecificQuestion,
    ProjectsClientSpecificQuestionsUpdateError,
    RequestBodies.ClientSpecificQuestion,
    {},
    {},
    ProjectsClientSpecificQuestionsUpdatePathParams
  >({
    url: '/projects/{projectPk}/client-specific-questions/{questionPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsClientSpecificQuestionsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ClientSpecificQuestion,
      ProjectsClientSpecificQuestionsUpdateError,
      ProjectsClientSpecificQuestionsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    Schemas.ClientSpecificQuestion,
    ProjectsClientSpecificQuestionsUpdateError,
    ProjectsClientSpecificQuestionsUpdateVariables
  >({
    mutationFn: (variables: ProjectsClientSpecificQuestionsUpdateVariables) =>
      fetchProjectsClientSpecificQuestionsUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsClientSpecificQuestionsDeletePathParams = {
  projectPk: string;
  questionPk: string;
};

export type ProjectsClientSpecificQuestionsDeleteError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsClientSpecificQuestionsDeleteVariables = {
  pathParams: ProjectsClientSpecificQuestionsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsClientSpecificQuestionsDelete = (
  variables: ProjectsClientSpecificQuestionsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsClientSpecificQuestionsDeleteError,
    undefined,
    {},
    {},
    ProjectsClientSpecificQuestionsDeletePathParams
  >({
    url: '/projects/{projectPk}/client-specific-questions/{questionPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsClientSpecificQuestionsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsClientSpecificQuestionsDeleteError,
      ProjectsClientSpecificQuestionsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsClientSpecificQuestionsDeleteError,
    ProjectsClientSpecificQuestionsDeleteVariables
  >({
    mutationFn: (variables: ProjectsClientSpecificQuestionsDeleteVariables) =>
      fetchProjectsClientSpecificQuestionsDelete({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsContextsListPathParams = {
  projectPk: string;
};

export type ProjectsContextsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsListResponse = Schemas.Context[];

export type ProjectsContextsListVariables = {
  pathParams: ProjectsContextsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsList = (
  variables: ProjectsContextsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsContextsListResponse,
    ProjectsContextsListError,
    undefined,
    {},
    {},
    ProjectsContextsListPathParams
  >({
    url: '/projects/{projectPk}/contexts',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsContextsList = <TData = ProjectsContextsListResponse>(
  variables: ProjectsContextsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsContextsListResponse,
      ProjectsContextsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsContextsListResponse,
    ProjectsContextsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/contexts',
      operationId: 'projectsContextsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsContextsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsContextsCreatePathParams = {
  projectPk: string;
};

export type ProjectsContextsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsCreateVariables = {
  pathParams: ProjectsContextsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsCreate = (
  variables: ProjectsContextsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsContextsCreateError,
    undefined,
    {},
    {},
    ProjectsContextsCreatePathParams
  >({
    url: '/projects/{projectPk}/contexts',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsContextsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsContextsCreateError,
      ProjectsContextsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsContextsCreateError,
    ProjectsContextsCreateVariables
  >({
    mutationFn: (variables: ProjectsContextsCreateVariables) =>
      fetchProjectsContextsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsContextsReadPathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsReadVariables = {
  pathParams: ProjectsContextsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsRead = (
  variables: ProjectsContextsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Context,
    ProjectsContextsReadError,
    undefined,
    {},
    {},
    ProjectsContextsReadPathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsContextsRead = <TData = Schemas.Context>(
  variables: ProjectsContextsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Context,
      ProjectsContextsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Context, ProjectsContextsReadError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/projects/{projectPk}/contexts/{contextPk}',
        operationId: 'projectsContextsRead',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchProjectsContextsRead({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    }
  );
};

export type ProjectsContextsUpdatePathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsUpdateVariables = {
  pathParams: ProjectsContextsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsUpdate = (
  variables: ProjectsContextsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsContextsUpdateError,
    undefined,
    {},
    {},
    ProjectsContextsUpdatePathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsContextsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsContextsUpdateError,
      ProjectsContextsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsContextsUpdateError,
    ProjectsContextsUpdateVariables
  >({
    mutationFn: (variables: ProjectsContextsUpdateVariables) =>
      fetchProjectsContextsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsContextsDeletePathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsDeleteVariables = {
  pathParams: ProjectsContextsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsDelete = (
  variables: ProjectsContextsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsContextsDeleteError,
    undefined,
    {},
    {},
    ProjectsContextsDeletePathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsContextsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsContextsDeleteError,
      ProjectsContextsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsContextsDeleteError,
    ProjectsContextsDeleteVariables
  >({
    mutationFn: (variables: ProjectsContextsDeleteVariables) =>
      fetchProjectsContextsDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsContextsRepositoriesListPathParams = {
  projectPk: string;
  contextPk: string;
};

export type ProjectsContextsRepositoriesListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsContextsRepositoriesListResponse = Schemas.Repository[];

export type ProjectsContextsRepositoriesListVariables = {
  pathParams: ProjectsContextsRepositoriesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsContextsRepositoriesList = (
  variables: ProjectsContextsRepositoriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsContextsRepositoriesListResponse,
    ProjectsContextsRepositoriesListError,
    undefined,
    {},
    {},
    ProjectsContextsRepositoriesListPathParams
  >({
    url: '/projects/{projectPk}/contexts/{contextPk}/repositories',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsContextsRepositoriesList = <
  TData = ProjectsContextsRepositoriesListResponse
>(
  variables: ProjectsContextsRepositoriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsContextsRepositoriesListResponse,
      ProjectsContextsRepositoriesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsContextsRepositoriesListResponse,
    ProjectsContextsRepositoriesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/contexts/{contextPk}/repositories',
      operationId: 'projectsContextsRepositoriesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsContextsRepositoriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFactsTicketingStatusListPathParams = {
  projectPk: string;
};

export type ProjectsFactsTicketingStatusListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFactsTicketingStatusListResponse =
  Schemas.FactsTicketingStatus[];

export type ProjectsFactsTicketingStatusListVariables = {
  pathParams: ProjectsFactsTicketingStatusListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFactsTicketingStatusList = (
  variables: ProjectsFactsTicketingStatusListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFactsTicketingStatusListResponse,
    ProjectsFactsTicketingStatusListError,
    undefined,
    {},
    {},
    ProjectsFactsTicketingStatusListPathParams
  >({
    url: '/projects/{projectPk}/facts_ticketing_status',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFactsTicketingStatusList = <
  TData = ProjectsFactsTicketingStatusListResponse
>(
  variables: ProjectsFactsTicketingStatusListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFactsTicketingStatusListResponse,
      ProjectsFactsTicketingStatusListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFactsTicketingStatusListResponse,
    ProjectsFactsTicketingStatusListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/facts_ticketing_status',
      operationId: 'projectsFactsTicketingStatusList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFactsTicketingStatusList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFactsTicketingStatusReadPathParams = {
  projectPk: string;
  factsTicketingStatusPk: string;
};

export type ProjectsFactsTicketingStatusReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFactsTicketingStatusReadVariables = {
  pathParams: ProjectsFactsTicketingStatusReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFactsTicketingStatusRead = (
  variables: ProjectsFactsTicketingStatusReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FactsTicketingStatus,
    ProjectsFactsTicketingStatusReadError,
    undefined,
    {},
    {},
    ProjectsFactsTicketingStatusReadPathParams
  >({
    url: '/projects/{projectPk}/facts_ticketing_status/{factsTicketingStatusPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFactsTicketingStatusRead = <
  TData = Schemas.FactsTicketingStatus
>(
  variables: ProjectsFactsTicketingStatusReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FactsTicketingStatus,
      ProjectsFactsTicketingStatusReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FactsTicketingStatus,
    ProjectsFactsTicketingStatusReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/facts_ticketing_status/{factsTicketingStatusPk}',
      operationId: 'projectsFactsTicketingStatusRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFactsTicketingStatusRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFactsTicketingStatusUpdatePathParams = {
  projectPk: string;
  factsTicketingStatusPk: string;
};

export type ProjectsFactsTicketingStatusUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFactsTicketingStatusUpdateVariables = {
  pathParams: ProjectsFactsTicketingStatusUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFactsTicketingStatusUpdate = (
  variables: ProjectsFactsTicketingStatusUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFactsTicketingStatusUpdateError,
    undefined,
    {},
    {},
    ProjectsFactsTicketingStatusUpdatePathParams
  >({
    url: '/projects/{projectPk}/facts_ticketing_status/{factsTicketingStatusPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsFactsTicketingStatusUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsFactsTicketingStatusUpdateError,
      ProjectsFactsTicketingStatusUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsFactsTicketingStatusUpdateError,
    ProjectsFactsTicketingStatusUpdateVariables
  >({
    mutationFn: (variables: ProjectsFactsTicketingStatusUpdateVariables) =>
      fetchProjectsFactsTicketingStatusUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsFactsTicketingTypeListPathParams = {
  projectPk: string;
};

export type ProjectsFactsTicketingTypeListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFactsTicketingTypeListResponse =
  Schemas.FactsTicketingType[];

export type ProjectsFactsTicketingTypeListVariables = {
  pathParams: ProjectsFactsTicketingTypeListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFactsTicketingTypeList = (
  variables: ProjectsFactsTicketingTypeListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsFactsTicketingTypeListResponse,
    ProjectsFactsTicketingTypeListError,
    undefined,
    {},
    {},
    ProjectsFactsTicketingTypeListPathParams
  >({
    url: '/projects/{projectPk}/facts_ticketing_type',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFactsTicketingTypeList = <
  TData = ProjectsFactsTicketingTypeListResponse
>(
  variables: ProjectsFactsTicketingTypeListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsFactsTicketingTypeListResponse,
      ProjectsFactsTicketingTypeListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsFactsTicketingTypeListResponse,
    ProjectsFactsTicketingTypeListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/facts_ticketing_type',
      operationId: 'projectsFactsTicketingTypeList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFactsTicketingTypeList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFactsTicketingTypeReadPathParams = {
  projectPk: string;
  factsTicketingTypePk: string;
};

export type ProjectsFactsTicketingTypeReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFactsTicketingTypeReadVariables = {
  pathParams: ProjectsFactsTicketingTypeReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFactsTicketingTypeRead = (
  variables: ProjectsFactsTicketingTypeReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.FactsTicketingType,
    ProjectsFactsTicketingTypeReadError,
    undefined,
    {},
    {},
    ProjectsFactsTicketingTypeReadPathParams
  >({
    url: '/projects/{projectPk}/facts_ticketing_type/{factsTicketingTypePk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsFactsTicketingTypeRead = <
  TData = Schemas.FactsTicketingType
>(
  variables: ProjectsFactsTicketingTypeReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.FactsTicketingType,
      ProjectsFactsTicketingTypeReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.FactsTicketingType,
    ProjectsFactsTicketingTypeReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/facts_ticketing_type/{factsTicketingTypePk}',
      operationId: 'projectsFactsTicketingTypeRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsFactsTicketingTypeRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsFactsTicketingTypeUpdatePathParams = {
  projectPk: string;
  factsTicketingTypePk: string;
};

export type ProjectsFactsTicketingTypeUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsFactsTicketingTypeUpdateVariables = {
  pathParams: ProjectsFactsTicketingTypeUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsFactsTicketingTypeUpdate = (
  variables: ProjectsFactsTicketingTypeUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsFactsTicketingTypeUpdateError,
    undefined,
    {},
    {},
    ProjectsFactsTicketingTypeUpdatePathParams
  >({
    url: '/projects/{projectPk}/facts_ticketing_type/{factsTicketingTypePk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsFactsTicketingTypeUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsFactsTicketingTypeUpdateError,
      ProjectsFactsTicketingTypeUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsFactsTicketingTypeUpdateError,
    ProjectsFactsTicketingTypeUpdateVariables
  >({
    mutationFn: (variables: ProjectsFactsTicketingTypeUpdateVariables) =>
      fetchProjectsFactsTicketingTypeUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsImportPeopleCreatePathParams = {
  projectPk: string;
};

export type ProjectsImportPeopleCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsImportPeopleCreateResponse = {
  /**
   * IDs of teams created
   */
  created_teams?: string[];
  /**
   * IDs of teams created
   */
  updated_teams?: string[];
  /**
   * IDs of teams created
   */
  created_people?: string[];
  /**
   * IDs of teams created
   */
  updated_people?: string[];
};

export type ProjectsImportPeopleCreateVariables = {
  body: void;
  pathParams: ProjectsImportPeopleCreatePathParams;
} & OneApiContext['fetcherOptions'];

/**
 * Import people and teams from an Excel file
 */
export const fetchProjectsImportPeopleCreate = (
  variables: ProjectsImportPeopleCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsImportPeopleCreateResponse,
    ProjectsImportPeopleCreateError,
    void,
    {},
    {},
    ProjectsImportPeopleCreatePathParams
  >({
    url: '/projects/{projectPk}/import_people',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Import people and teams from an Excel file
 */
export const useProjectsImportPeopleCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsImportPeopleCreateResponse,
      ProjectsImportPeopleCreateError,
      ProjectsImportPeopleCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsImportPeopleCreateResponse,
    ProjectsImportPeopleCreateError,
    ProjectsImportPeopleCreateVariables
  >({
    mutationFn: (variables: ProjectsImportPeopleCreateVariables) =>
      fetchProjectsImportPeopleCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsLanguageRelevanceListPathParams = {
  projectPk: string;
};

export type ProjectsLanguageRelevanceListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsLanguageRelevanceListResponse = Schemas.LanguageRelevance[];

export type ProjectsLanguageRelevanceListVariables = {
  pathParams: ProjectsLanguageRelevanceListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsLanguageRelevanceList = (
  variables: ProjectsLanguageRelevanceListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsLanguageRelevanceListResponse,
    ProjectsLanguageRelevanceListError,
    undefined,
    {},
    {},
    ProjectsLanguageRelevanceListPathParams
  >({
    url: '/projects/{projectPk}/language_relevance',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsLanguageRelevanceList = <
  TData = ProjectsLanguageRelevanceListResponse
>(
  variables: ProjectsLanguageRelevanceListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsLanguageRelevanceListResponse,
      ProjectsLanguageRelevanceListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsLanguageRelevanceListResponse,
    ProjectsLanguageRelevanceListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/language_relevance',
      operationId: 'projectsLanguageRelevanceList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsLanguageRelevanceList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsLanguageRelevanceReadPathParams = {
  projectPk: string;
  languagePk: string;
};

export type ProjectsLanguageRelevanceReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsLanguageRelevanceReadVariables = {
  pathParams: ProjectsLanguageRelevanceReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsLanguageRelevanceRead = (
  variables: ProjectsLanguageRelevanceReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.LanguageRelevance,
    ProjectsLanguageRelevanceReadError,
    undefined,
    {},
    {},
    ProjectsLanguageRelevanceReadPathParams
  >({
    url: '/projects/{projectPk}/language_relevance/{languagePk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsLanguageRelevanceRead = <
  TData = Schemas.LanguageRelevance
>(
  variables: ProjectsLanguageRelevanceReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LanguageRelevance,
      ProjectsLanguageRelevanceReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.LanguageRelevance,
    ProjectsLanguageRelevanceReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/language_relevance/{languagePk}',
      operationId: 'projectsLanguageRelevanceRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsLanguageRelevanceRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsLanguageRelevanceUpdatePathParams = {
  projectPk: string;
  languagePk: string;
};

export type ProjectsLanguageRelevanceUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsLanguageRelevanceUpdateVariables = {
  pathParams: ProjectsLanguageRelevanceUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsLanguageRelevanceUpdate = (
  variables: ProjectsLanguageRelevanceUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsLanguageRelevanceUpdateError,
    undefined,
    {},
    {},
    ProjectsLanguageRelevanceUpdatePathParams
  >({
    url: '/projects/{projectPk}/language_relevance/{languagePk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsLanguageRelevanceUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsLanguageRelevanceUpdateError,
      ProjectsLanguageRelevanceUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsLanguageRelevanceUpdateError,
    ProjectsLanguageRelevanceUpdateVariables
  >({
    mutationFn: (variables: ProjectsLanguageRelevanceUpdateVariables) =>
      fetchProjectsLanguageRelevanceUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsLanguageStatisticsListPathParams = {
  projectPk: string;
};

export type ProjectsLanguageStatisticsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsLanguageStatisticsListResponse =
  Schemas.OneLanguageStatistics[];

export type ProjectsLanguageStatisticsListVariables = {
  pathParams: ProjectsLanguageStatisticsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsLanguageStatisticsList = (
  variables: ProjectsLanguageStatisticsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsLanguageStatisticsListResponse,
    ProjectsLanguageStatisticsListError,
    undefined,
    {},
    {},
    ProjectsLanguageStatisticsListPathParams
  >({
    url: '/projects/{projectPk}/language_statistics',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsLanguageStatisticsList = <
  TData = ProjectsLanguageStatisticsListResponse
>(
  variables: ProjectsLanguageStatisticsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsLanguageStatisticsListResponse,
      ProjectsLanguageStatisticsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsLanguageStatisticsListResponse,
    ProjectsLanguageStatisticsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/language_statistics',
      operationId: 'projectsLanguageStatisticsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsLanguageStatisticsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsLanguageStatisticsReadPathParams = {
  projectPk: string;
  languagePk: string;
};

export type ProjectsLanguageStatisticsReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsLanguageStatisticsReadVariables = {
  pathParams: ProjectsLanguageStatisticsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsLanguageStatisticsRead = (
  variables: ProjectsLanguageStatisticsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.OneLanguageStatistics,
    ProjectsLanguageStatisticsReadError,
    undefined,
    {},
    {},
    ProjectsLanguageStatisticsReadPathParams
  >({
    url: '/projects/{projectPk}/language_statistics/{languagePk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsLanguageStatisticsRead = <
  TData = Schemas.OneLanguageStatistics
>(
  variables: ProjectsLanguageStatisticsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OneLanguageStatistics,
      ProjectsLanguageStatisticsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.OneLanguageStatistics,
    ProjectsLanguageStatisticsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/language_statistics/{languagePk}',
      operationId: 'projectsLanguageStatisticsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsLanguageStatisticsRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsMergePersonsCreatePathParams = {
  projectPk: string;
};

export type ProjectsMergePersonsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsMergePersonsCreateResponse = {
  /**
   * ID of the survivor user
   */
  survivor_user_id?: string;
  /**
   * ID of the user to be merged into the survivor user
   */
  to_be_merged_user_id?: string;
};

export type ProjectsMergePersonsCreateRequestBody = {
  /**
   * ID of the survivor user
   */
  survivor_user_id?: string;
  /**
   * ID of the user to be merged into the survivor user
   */
  to_be_merged_user_id?: string;
};

export type ProjectsMergePersonsCreateVariables = {
  body?: ProjectsMergePersonsCreateRequestBody;
  pathParams: ProjectsMergePersonsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsMergePersonsCreate = (
  variables: ProjectsMergePersonsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMergePersonsCreateResponse,
    ProjectsMergePersonsCreateError,
    ProjectsMergePersonsCreateRequestBody,
    {},
    {},
    ProjectsMergePersonsCreatePathParams
  >({
    url: '/projects/{projectPk}/merge-persons',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsMergePersonsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsMergePersonsCreateResponse,
      ProjectsMergePersonsCreateError,
      ProjectsMergePersonsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsMergePersonsCreateResponse,
    ProjectsMergePersonsCreateError,
    ProjectsMergePersonsCreateVariables
  >({
    mutationFn: (variables: ProjectsMergePersonsCreateVariables) =>
      fetchProjectsMergePersonsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsMovePersonAccountCreatePathParams = {
  projectPk: string;
};

export type ProjectsMovePersonAccountCreateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsMovePersonAccountCreateResponse = {
  /**
   * ID of the account to move
   */
  account_id?: string;
  /**
   * ID of the person to which account will be moved
   */
  person_id?: string;
  /**
   * Name of the table containing given account
   */
  source_table?: string;
};

export type ProjectsMovePersonAccountCreateRequestBody = {
  /**
   * ID of the account to move
   */
  account_id?: string;
  /**
   * ID of the person to which account will be moved
   */
  person_id?: string;
  /**
   * Name of the table containing given account
   */
  source_table?: string;
};

export type ProjectsMovePersonAccountCreateVariables = {
  body?: ProjectsMovePersonAccountCreateRequestBody;
  pathParams: ProjectsMovePersonAccountCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsMovePersonAccountCreate = (
  variables: ProjectsMovePersonAccountCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsMovePersonAccountCreateResponse,
    ProjectsMovePersonAccountCreateError,
    ProjectsMovePersonAccountCreateRequestBody,
    {},
    {},
    ProjectsMovePersonAccountCreatePathParams
  >({
    url: '/projects/{projectPk}/move-person-account',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsMovePersonAccountCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsMovePersonAccountCreateResponse,
      ProjectsMovePersonAccountCreateError,
      ProjectsMovePersonAccountCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsMovePersonAccountCreateResponse,
    ProjectsMovePersonAccountCreateError,
    ProjectsMovePersonAccountCreateVariables
  >({
    mutationFn: (variables: ProjectsMovePersonAccountCreateVariables) =>
      fetchProjectsMovePersonAccountCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsPersonFromNonForkedReposListPathParams = {
  projectPk: string;
};

export type ProjectsPersonFromNonForkedReposListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonFromNonForkedReposListResponse = Schemas.Person[];

export type ProjectsPersonFromNonForkedReposListVariables = {
  pathParams: ProjectsPersonFromNonForkedReposListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonFromNonForkedReposList = (
  variables: ProjectsPersonFromNonForkedReposListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsPersonFromNonForkedReposListResponse,
    ProjectsPersonFromNonForkedReposListError,
    undefined,
    {},
    {},
    ProjectsPersonFromNonForkedReposListPathParams
  >({
    url: '/projects/{projectPk}/person-from-non-forked-repos',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonFromNonForkedReposList = <
  TData = ProjectsPersonFromNonForkedReposListResponse
>(
  variables: ProjectsPersonFromNonForkedReposListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsPersonFromNonForkedReposListResponse,
      ProjectsPersonFromNonForkedReposListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsPersonFromNonForkedReposListResponse,
    ProjectsPersonFromNonForkedReposListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/person-from-non-forked-repos',
      operationId: 'projectsPersonFromNonForkedReposList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsPersonFromNonForkedReposList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsPersonAccountsListPathParams = {
  projectPk: string;
};

export type ProjectsPersonAccountsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonAccountsListResponse = Schemas.OnePersonAccount[];

export type ProjectsPersonAccountsListVariables = {
  pathParams: ProjectsPersonAccountsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonAccountsList = (
  variables: ProjectsPersonAccountsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsPersonAccountsListResponse,
    ProjectsPersonAccountsListError,
    undefined,
    {},
    {},
    ProjectsPersonAccountsListPathParams
  >({
    url: '/projects/{projectPk}/person_accounts',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonAccountsList = <
  TData = ProjectsPersonAccountsListResponse
>(
  variables: ProjectsPersonAccountsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsPersonAccountsListResponse,
      ProjectsPersonAccountsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsPersonAccountsListResponse,
    ProjectsPersonAccountsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/person_accounts',
      operationId: 'projectsPersonAccountsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsPersonAccountsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsPersonAccountsReadPathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonAccountsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonAccountsReadResponse = Schemas.OnePersonAccount[];

export type ProjectsPersonAccountsReadVariables = {
  pathParams: ProjectsPersonAccountsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonAccountsRead = (
  variables: ProjectsPersonAccountsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsPersonAccountsReadResponse,
    ProjectsPersonAccountsReadError,
    undefined,
    {},
    {},
    ProjectsPersonAccountsReadPathParams
  >({
    url: '/projects/{projectPk}/person_accounts/{personPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonAccountsRead = <
  TData = ProjectsPersonAccountsReadResponse
>(
  variables: ProjectsPersonAccountsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsPersonAccountsReadResponse,
      ProjectsPersonAccountsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsPersonAccountsReadResponse,
    ProjectsPersonAccountsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/person_accounts/{personPk}',
      operationId: 'projectsPersonAccountsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsPersonAccountsRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsPersonsListPathParams = {
  projectPk: string;
};

export type ProjectsPersonsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsListResponse = Schemas.Person[];

export type ProjectsPersonsListVariables = {
  pathParams: ProjectsPersonsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsList = (
  variables: ProjectsPersonsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsPersonsListResponse,
    ProjectsPersonsListError,
    undefined,
    {},
    {},
    ProjectsPersonsListPathParams
  >({
    url: '/projects/{projectPk}/persons',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonsList = <TData = ProjectsPersonsListResponse>(
  variables: ProjectsPersonsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsPersonsListResponse,
      ProjectsPersonsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsPersonsListResponse,
    ProjectsPersonsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/persons',
      operationId: 'projectsPersonsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsPersonsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsPersonsCreatePathParams = {
  projectPk: string;
};

export type ProjectsPersonsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsCreateVariables = {
  pathParams: ProjectsPersonsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsCreate = (
  variables: ProjectsPersonsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsCreateError,
    undefined,
    {},
    {},
    ProjectsPersonsCreatePathParams
  >({
    url: '/projects/{projectPk}/persons',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsPersonsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsPersonsCreateError,
      ProjectsPersonsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsPersonsCreateError,
    ProjectsPersonsCreateVariables
  >({
    mutationFn: (variables: ProjectsPersonsCreateVariables) =>
      fetchProjectsPersonsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsPersonsReadPathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsReadVariables = {
  pathParams: ProjectsPersonsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsRead = (
  variables: ProjectsPersonsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Person,
    ProjectsPersonsReadError,
    undefined,
    {},
    {},
    ProjectsPersonsReadPathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonsRead = <TData = Schemas.Person>(
  variables: ProjectsPersonsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Person, ProjectsPersonsReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Person, ProjectsPersonsReadError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/persons/{personPk}',
      operationId: 'projectsPersonsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsPersonsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsPersonsUpdatePathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsUpdateVariables = {
  pathParams: ProjectsPersonsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsUpdate = (
  variables: ProjectsPersonsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsUpdateError,
    undefined,
    {},
    {},
    ProjectsPersonsUpdatePathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsPersonsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsPersonsUpdateError,
      ProjectsPersonsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsPersonsUpdateError,
    ProjectsPersonsUpdateVariables
  >({
    mutationFn: (variables: ProjectsPersonsUpdateVariables) =>
      fetchProjectsPersonsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsPersonsDeletePathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsDeleteVariables = {
  pathParams: ProjectsPersonsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsDelete = (
  variables: ProjectsPersonsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsDeleteError,
    undefined,
    {},
    {},
    ProjectsPersonsDeletePathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsPersonsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsPersonsDeleteError,
      ProjectsPersonsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsPersonsDeleteError,
    ProjectsPersonsDeleteVariables
  >({
    mutationFn: (variables: ProjectsPersonsDeleteVariables) =>
      fetchProjectsPersonsDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsPersonsRolesListPathParams = {
  projectPk: string;
  personPk: string;
};

export type ProjectsPersonsRolesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsPersonsRolesListVariables = {
  pathParams: ProjectsPersonsRolesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsPersonsRolesList = (
  variables: ProjectsPersonsRolesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsPersonsRolesListError,
    undefined,
    {},
    {},
    ProjectsPersonsRolesListPathParams
  >({
    url: '/projects/{projectPk}/persons/{personPk}/roles',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsPersonsRolesList = <TData = undefined>(
  variables: ProjectsPersonsRolesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProjectsPersonsRolesListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, ProjectsPersonsRolesListError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/persons/{personPk}/roles',
      operationId: 'projectsPersonsRolesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsPersonsRolesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsRepositoriesListPathParams = {
  projectPk: string;
};

export type ProjectsRepositoriesListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsRepositoriesListResponse = Schemas.Repository[];

export type ProjectsRepositoriesListVariables = {
  pathParams: ProjectsRepositoriesListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRepositoriesList = (
  variables: ProjectsRepositoriesListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsRepositoriesListResponse,
    ProjectsRepositoriesListError,
    undefined,
    {},
    {},
    ProjectsRepositoriesListPathParams
  >({
    url: '/projects/{projectPk}/repositories',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsRepositoriesList = <
  TData = ProjectsRepositoriesListResponse
>(
  variables: ProjectsRepositoriesListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsRepositoriesListResponse,
      ProjectsRepositoriesListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsRepositoriesListResponse,
    ProjectsRepositoriesListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/repositories',
      operationId: 'projectsRepositoriesList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsRepositoriesList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsRepositoriesReadPathParams = {
  projectPk: string;
  repositoryPk: string;
};

export type ProjectsRepositoriesReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsRepositoriesReadVariables = {
  pathParams: ProjectsRepositoriesReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRepositoriesRead = (
  variables: ProjectsRepositoriesReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Repository,
    ProjectsRepositoriesReadError,
    undefined,
    {},
    {},
    ProjectsRepositoriesReadPathParams
  >({
    url: '/projects/{projectPk}/repositories/{repositoryPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsRepositoriesRead = <TData = Schemas.Repository>(
  variables: ProjectsRepositoriesReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.Repository,
      ProjectsRepositoriesReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.Repository,
    ProjectsRepositoriesReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/repositories/{repositoryPk}',
      operationId: 'projectsRepositoriesRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsRepositoriesRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsRepositoriesUpdatePathParams = {
  projectPk: string;
  repositoryPk: string;
};

export type ProjectsRepositoriesUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsRepositoriesUpdateVariables = {
  pathParams: ProjectsRepositoriesUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsRepositoriesUpdate = (
  variables: ProjectsRepositoriesUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsRepositoriesUpdateError,
    undefined,
    {},
    {},
    ProjectsRepositoriesUpdatePathParams
  >({
    url: '/projects/{projectPk}/repositories/{repositoryPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsRepositoriesUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsRepositoriesUpdateError,
      ProjectsRepositoriesUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsRepositoriesUpdateError,
    ProjectsRepositoriesUpdateVariables
  >({
    mutationFn: (variables: ProjectsRepositoriesUpdateVariables) =>
      fetchProjectsRepositoriesUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTeamMembersBlockUpdatePathParams = {
  projectPk: string;
  teamMemberId: string;
};

export type ProjectsTeamMembersBlockUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamMembersBlockUpdateResponse = {
  id?: string;
  username?: string;
  blocked?: boolean;
};

export type ProjectsTeamMembersBlockUpdateRequestBody = {
  blocked?: boolean;
};

export type ProjectsTeamMembersBlockUpdateVariables = {
  body?: ProjectsTeamMembersBlockUpdateRequestBody;
  pathParams: ProjectsTeamMembersBlockUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamMembersBlockUpdate = (
  variables: ProjectsTeamMembersBlockUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTeamMembersBlockUpdateResponse,
    ProjectsTeamMembersBlockUpdateError,
    ProjectsTeamMembersBlockUpdateRequestBody,
    {},
    {},
    ProjectsTeamMembersBlockUpdatePathParams
  >({
    url: '/projects/{projectPk}/team-members/{teamMemberId}/block',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTeamMembersBlockUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      ProjectsTeamMembersBlockUpdateResponse,
      ProjectsTeamMembersBlockUpdateError,
      ProjectsTeamMembersBlockUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    ProjectsTeamMembersBlockUpdateResponse,
    ProjectsTeamMembersBlockUpdateError,
    ProjectsTeamMembersBlockUpdateVariables
  >({
    mutationFn: (variables: ProjectsTeamMembersBlockUpdateVariables) =>
      fetchProjectsTeamMembersBlockUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTeamsListPathParams = {
  projectPk: string;
};

export type ProjectsTeamsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsListResponse = Schemas.Team[];

export type ProjectsTeamsListVariables = {
  pathParams: ProjectsTeamsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsList = (
  variables: ProjectsTeamsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTeamsListResponse,
    ProjectsTeamsListError,
    undefined,
    {},
    {},
    ProjectsTeamsListPathParams
  >({
    url: '/projects/{projectPk}/teams',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTeamsList = <TData = ProjectsTeamsListResponse>(
  variables: ProjectsTeamsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTeamsListResponse,
      ProjectsTeamsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTeamsListResponse,
    ProjectsTeamsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/teams',
      operationId: 'projectsTeamsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTeamsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTeamsCreatePathParams = {
  projectPk: string;
};

export type ProjectsTeamsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsCreateVariables = {
  pathParams: ProjectsTeamsCreatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsCreate = (
  variables: ProjectsTeamsCreateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTeamsCreateError,
    undefined,
    {},
    {},
    ProjectsTeamsCreatePathParams
  >({
    url: '/projects/{projectPk}/teams',
    method: 'post',
    ...variables,
    signal,
  });

export const useProjectsTeamsCreate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTeamsCreateError,
      ProjectsTeamsCreateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTeamsCreateError,
    ProjectsTeamsCreateVariables
  >({
    mutationFn: (variables: ProjectsTeamsCreateVariables) =>
      fetchProjectsTeamsCreate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTeamsReadPathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsReadError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsReadVariables = {
  pathParams: ProjectsTeamsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsRead = (
  variables: ProjectsTeamsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.Team,
    ProjectsTeamsReadError,
    undefined,
    {},
    {},
    ProjectsTeamsReadPathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTeamsRead = <TData = Schemas.Team>(
  variables: ProjectsTeamsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Team, ProjectsTeamsReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.Team, ProjectsTeamsReadError, TData>({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/teams/{teamPk}',
      operationId: 'projectsTeamsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTeamsRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTeamsUpdatePathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsUpdateVariables = {
  pathParams: ProjectsTeamsUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsUpdate = (
  variables: ProjectsTeamsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTeamsUpdateError,
    undefined,
    {},
    {},
    ProjectsTeamsUpdatePathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTeamsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTeamsUpdateError,
      ProjectsTeamsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTeamsUpdateError,
    ProjectsTeamsUpdateVariables
  >({
    mutationFn: (variables: ProjectsTeamsUpdateVariables) =>
      fetchProjectsTeamsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTeamsDeletePathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsDeleteVariables = {
  pathParams: ProjectsTeamsDeletePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsDelete = (
  variables: ProjectsTeamsDeleteVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTeamsDeleteError,
    undefined,
    {},
    {},
    ProjectsTeamsDeletePathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProjectsTeamsDelete = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTeamsDeleteError,
      ProjectsTeamsDeleteVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTeamsDeleteError,
    ProjectsTeamsDeleteVariables
  >({
    mutationFn: (variables: ProjectsTeamsDeleteVariables) =>
      fetchProjectsTeamsDelete({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProjectsTeamsPersonsListPathParams = {
  projectPk: string;
  teamPk: string;
};

export type ProjectsTeamsPersonsListError = Fetcher.ErrorWrapper<undefined>;

export type ProjectsTeamsPersonsListResponse = Schemas.Person[];

export type ProjectsTeamsPersonsListVariables = {
  pathParams: ProjectsTeamsPersonsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTeamsPersonsList = (
  variables: ProjectsTeamsPersonsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTeamsPersonsListResponse,
    ProjectsTeamsPersonsListError,
    undefined,
    {},
    {},
    ProjectsTeamsPersonsListPathParams
  >({
    url: '/projects/{projectPk}/teams/{teamPk}/persons',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTeamsPersonsList = <
  TData = ProjectsTeamsPersonsListResponse
>(
  variables: ProjectsTeamsPersonsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTeamsPersonsListResponse,
      ProjectsTeamsPersonsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTeamsPersonsListResponse,
    ProjectsTeamsPersonsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/teams/{teamPk}/persons',
      operationId: 'projectsTeamsPersonsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTeamsPersonsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemProjectListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemProjectListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemProjectListResponse =
  Schemas.TicketingSystemProject[];

export type ProjectsTicketingSystemProjectListVariables = {
  pathParams: ProjectsTicketingSystemProjectListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemProjectList = (
  variables: ProjectsTicketingSystemProjectListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemProjectListResponse,
    ProjectsTicketingSystemProjectListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemProjectListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_project',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemProjectList = <
  TData = ProjectsTicketingSystemProjectListResponse
>(
  variables: ProjectsTicketingSystemProjectListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemProjectListResponse,
      ProjectsTicketingSystemProjectListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemProjectListResponse,
    ProjectsTicketingSystemProjectListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_project',
      operationId: 'projectsTicketingSystemProjectList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemProjectList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemProjectReadPathParams = {
  projectPk: string;
  ticketingProjectPk: string;
};

export type ProjectsTicketingSystemProjectReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemProjectReadVariables = {
  pathParams: ProjectsTicketingSystemProjectReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemProjectRead = (
  variables: ProjectsTicketingSystemProjectReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TicketingSystemProject,
    ProjectsTicketingSystemProjectReadError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemProjectReadPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_project/{ticketingProjectPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemProjectRead = <
  TData = Schemas.TicketingSystemProject
>(
  variables: ProjectsTicketingSystemProjectReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TicketingSystemProject,
      ProjectsTicketingSystemProjectReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.TicketingSystemProject,
    ProjectsTicketingSystemProjectReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_project/{ticketingProjectPk}',
      operationId: 'projectsTicketingSystemProjectRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemProjectRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemProjectUpdatePathParams = {
  projectPk: string;
  ticketingProjectPk: string;
};

export type ProjectsTicketingSystemProjectUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemProjectUpdateVariables = {
  pathParams: ProjectsTicketingSystemProjectUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemProjectUpdate = (
  variables: ProjectsTicketingSystemProjectUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTicketingSystemProjectUpdateError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemProjectUpdatePathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_project/{ticketingProjectPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemProjectUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTicketingSystemProjectUpdateError,
      ProjectsTicketingSystemProjectUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTicketingSystemProjectUpdateError,
    ProjectsTicketingSystemProjectUpdateVariables
  >({
    mutationFn: (variables: ProjectsTicketingSystemProjectUpdateVariables) =>
      fetchProjectsTicketingSystemProjectUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsTicketingSystemStatisticsListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemStatisticsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatisticsListResponse =
  Schemas.OneTicketingSystemProject[];

export type ProjectsTicketingSystemStatisticsListVariables = {
  pathParams: ProjectsTicketingSystemStatisticsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatisticsList = (
  variables: ProjectsTicketingSystemStatisticsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemStatisticsListResponse,
    ProjectsTicketingSystemStatisticsListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatisticsListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_statistics',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatisticsList = <
  TData = ProjectsTicketingSystemStatisticsListResponse
>(
  variables: ProjectsTicketingSystemStatisticsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemStatisticsListResponse,
      ProjectsTicketingSystemStatisticsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemStatisticsListResponse,
    ProjectsTicketingSystemStatisticsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_statistics',
      operationId: 'projectsTicketingSystemStatisticsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemStatisticsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemStatisticsReadPathParams = {
  projectPk: string;
  ticketingProjectPk: string;
};

export type ProjectsTicketingSystemStatisticsReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatisticsReadVariables = {
  pathParams: ProjectsTicketingSystemStatisticsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatisticsRead = (
  variables: ProjectsTicketingSystemStatisticsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.OneTicketingSystemProject,
    ProjectsTicketingSystemStatisticsReadError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatisticsReadPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_statistics/{ticketingProjectPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatisticsRead = <
  TData = Schemas.OneTicketingSystemProject
>(
  variables: ProjectsTicketingSystemStatisticsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OneTicketingSystemProject,
      ProjectsTicketingSystemStatisticsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.OneTicketingSystemProject,
    ProjectsTicketingSystemStatisticsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_statistics/{ticketingProjectPk}',
      operationId: 'projectsTicketingSystemStatisticsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemStatisticsRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemStatusListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemStatusListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatusListResponse =
  Schemas.TicketingSystemStatus[];

export type ProjectsTicketingSystemStatusListVariables = {
  pathParams: ProjectsTicketingSystemStatusListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatusList = (
  variables: ProjectsTicketingSystemStatusListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemStatusListResponse,
    ProjectsTicketingSystemStatusListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatusListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_status',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatusList = <
  TData = ProjectsTicketingSystemStatusListResponse
>(
  variables: ProjectsTicketingSystemStatusListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemStatusListResponse,
      ProjectsTicketingSystemStatusListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemStatusListResponse,
    ProjectsTicketingSystemStatusListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_status',
      operationId: 'projectsTicketingSystemStatusList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemStatusList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemStatusReadPathParams = {
  projectPk: string;
  ticketingStatusPk: string;
};

export type ProjectsTicketingSystemStatusReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatusReadVariables = {
  pathParams: ProjectsTicketingSystemStatusReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatusRead = (
  variables: ProjectsTicketingSystemStatusReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TicketingSystemStatus,
    ProjectsTicketingSystemStatusReadError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatusReadPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_status/{ticketingStatusPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatusRead = <
  TData = Schemas.TicketingSystemStatus
>(
  variables: ProjectsTicketingSystemStatusReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TicketingSystemStatus,
      ProjectsTicketingSystemStatusReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.TicketingSystemStatus,
    ProjectsTicketingSystemStatusReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_status/{ticketingStatusPk}',
      operationId: 'projectsTicketingSystemStatusRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemStatusRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemStatusUpdatePathParams = {
  projectPk: string;
  ticketingStatusPk: string;
};

export type ProjectsTicketingSystemStatusUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatusUpdateVariables = {
  pathParams: ProjectsTicketingSystemStatusUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatusUpdate = (
  variables: ProjectsTicketingSystemStatusUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTicketingSystemStatusUpdateError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatusUpdatePathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_status/{ticketingStatusPk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatusUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTicketingSystemStatusUpdateError,
      ProjectsTicketingSystemStatusUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTicketingSystemStatusUpdateError,
    ProjectsTicketingSystemStatusUpdateVariables
  >({
    mutationFn: (variables: ProjectsTicketingSystemStatusUpdateVariables) =>
      fetchProjectsTicketingSystemStatusUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsTicketingSystemStatusNotIgnoredStatisticsListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemStatusNotIgnoredStatisticsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatusNotIgnoredStatisticsListResponse =
  Schemas.OneTicketingSystemStatesStatistics[];

export type ProjectsTicketingSystemStatusNotIgnoredStatisticsListVariables = {
  pathParams: ProjectsTicketingSystemStatusNotIgnoredStatisticsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatusNotIgnoredStatisticsList = (
  variables: ProjectsTicketingSystemStatusNotIgnoredStatisticsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemStatusNotIgnoredStatisticsListResponse,
    ProjectsTicketingSystemStatusNotIgnoredStatisticsListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatusNotIgnoredStatisticsListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_status_not_ignored_statistics',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatusNotIgnoredStatisticsList = <
  TData = ProjectsTicketingSystemStatusNotIgnoredStatisticsListResponse
>(
  variables: ProjectsTicketingSystemStatusNotIgnoredStatisticsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemStatusNotIgnoredStatisticsListResponse,
      ProjectsTicketingSystemStatusNotIgnoredStatisticsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemStatusNotIgnoredStatisticsListResponse,
    ProjectsTicketingSystemStatusNotIgnoredStatisticsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_status_not_ignored_statistics',
      operationId: 'projectsTicketingSystemStatusNotIgnoredStatisticsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemStatusNotIgnoredStatisticsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemStatusStatisticsListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemStatusStatisticsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatusStatisticsListResponse =
  Schemas.OneTicketingSystemStatesStatistics[];

export type ProjectsTicketingSystemStatusStatisticsListVariables = {
  pathParams: ProjectsTicketingSystemStatusStatisticsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatusStatisticsList = (
  variables: ProjectsTicketingSystemStatusStatisticsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemStatusStatisticsListResponse,
    ProjectsTicketingSystemStatusStatisticsListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatusStatisticsListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_status_statistics',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatusStatisticsList = <
  TData = ProjectsTicketingSystemStatusStatisticsListResponse
>(
  variables: ProjectsTicketingSystemStatusStatisticsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemStatusStatisticsListResponse,
      ProjectsTicketingSystemStatusStatisticsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemStatusStatisticsListResponse,
    ProjectsTicketingSystemStatusStatisticsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_status_statistics',
      operationId: 'projectsTicketingSystemStatusStatisticsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemStatusStatisticsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemStatusStatisticsReadPathParams = {
  projectPk: string;
  ticketingStatusPk: string;
};

export type ProjectsTicketingSystemStatusStatisticsReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemStatusStatisticsReadVariables = {
  pathParams: ProjectsTicketingSystemStatusStatisticsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemStatusStatisticsRead = (
  variables: ProjectsTicketingSystemStatusStatisticsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.OneTicketingSystemStatesStatistics,
    ProjectsTicketingSystemStatusStatisticsReadError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemStatusStatisticsReadPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_status_statistics/{ticketingStatusPk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemStatusStatisticsRead = <
  TData = Schemas.OneTicketingSystemStatesStatistics
>(
  variables: ProjectsTicketingSystemStatusStatisticsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OneTicketingSystemStatesStatistics,
      ProjectsTicketingSystemStatusStatisticsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.OneTicketingSystemStatesStatistics,
    ProjectsTicketingSystemStatusStatisticsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_status_statistics/{ticketingStatusPk}',
      operationId: 'projectsTicketingSystemStatusStatisticsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemStatusStatisticsRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemTypeListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemTypeListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemTypeListResponse =
  Schemas.TicketingSystemType[];

export type ProjectsTicketingSystemTypeListVariables = {
  pathParams: ProjectsTicketingSystemTypeListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemTypeList = (
  variables: ProjectsTicketingSystemTypeListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemTypeListResponse,
    ProjectsTicketingSystemTypeListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemTypeListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_type',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemTypeList = <
  TData = ProjectsTicketingSystemTypeListResponse
>(
  variables: ProjectsTicketingSystemTypeListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemTypeListResponse,
      ProjectsTicketingSystemTypeListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemTypeListResponse,
    ProjectsTicketingSystemTypeListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_type',
      operationId: 'projectsTicketingSystemTypeList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemTypeList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemTypeReadPathParams = {
  projectPk: string;
  ticketingTypePk: string;
};

export type ProjectsTicketingSystemTypeReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemTypeReadVariables = {
  pathParams: ProjectsTicketingSystemTypeReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemTypeRead = (
  variables: ProjectsTicketingSystemTypeReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.TicketingSystemType,
    ProjectsTicketingSystemTypeReadError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemTypeReadPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_type/{ticketingTypePk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemTypeRead = <
  TData = Schemas.TicketingSystemType
>(
  variables: ProjectsTicketingSystemTypeReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TicketingSystemType,
      ProjectsTicketingSystemTypeReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.TicketingSystemType,
    ProjectsTicketingSystemTypeReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_type/{ticketingTypePk}',
      operationId: 'projectsTicketingSystemTypeRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemTypeRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemTypeUpdatePathParams = {
  projectPk: string;
  ticketingTypePk: string;
};

export type ProjectsTicketingSystemTypeUpdateError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemTypeUpdateVariables = {
  pathParams: ProjectsTicketingSystemTypeUpdatePathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemTypeUpdate = (
  variables: ProjectsTicketingSystemTypeUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    undefined,
    ProjectsTicketingSystemTypeUpdateError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemTypeUpdatePathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_type/{ticketingTypePk}',
    method: 'put',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemTypeUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProjectsTicketingSystemTypeUpdateError,
      ProjectsTicketingSystemTypeUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    ProjectsTicketingSystemTypeUpdateError,
    ProjectsTicketingSystemTypeUpdateVariables
  >({
    mutationFn: (variables: ProjectsTicketingSystemTypeUpdateVariables) =>
      fetchProjectsTicketingSystemTypeUpdate({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ProjectsTicketingSystemTypeNotIgnoredStatisticsListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemTypeNotIgnoredStatisticsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemTypeNotIgnoredStatisticsListResponse =
  Schemas.OneTicketingSystemTypesStatistics[];

export type ProjectsTicketingSystemTypeNotIgnoredStatisticsListVariables = {
  pathParams: ProjectsTicketingSystemTypeNotIgnoredStatisticsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemTypeNotIgnoredStatisticsList = (
  variables: ProjectsTicketingSystemTypeNotIgnoredStatisticsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemTypeNotIgnoredStatisticsListResponse,
    ProjectsTicketingSystemTypeNotIgnoredStatisticsListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemTypeNotIgnoredStatisticsListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_type_not_ignored_statistics',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemTypeNotIgnoredStatisticsList = <
  TData = ProjectsTicketingSystemTypeNotIgnoredStatisticsListResponse
>(
  variables: ProjectsTicketingSystemTypeNotIgnoredStatisticsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemTypeNotIgnoredStatisticsListResponse,
      ProjectsTicketingSystemTypeNotIgnoredStatisticsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemTypeNotIgnoredStatisticsListResponse,
    ProjectsTicketingSystemTypeNotIgnoredStatisticsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_type_not_ignored_statistics',
      operationId: 'projectsTicketingSystemTypeNotIgnoredStatisticsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemTypeNotIgnoredStatisticsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemTypeStatisticsListPathParams = {
  projectPk: string;
};

export type ProjectsTicketingSystemTypeStatisticsListError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemTypeStatisticsListResponse =
  Schemas.OneTicketingSystemTypesStatistics[];

export type ProjectsTicketingSystemTypeStatisticsListVariables = {
  pathParams: ProjectsTicketingSystemTypeStatisticsListPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemTypeStatisticsList = (
  variables: ProjectsTicketingSystemTypeStatisticsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    ProjectsTicketingSystemTypeStatisticsListResponse,
    ProjectsTicketingSystemTypeStatisticsListError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemTypeStatisticsListPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_type_statistics',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemTypeStatisticsList = <
  TData = ProjectsTicketingSystemTypeStatisticsListResponse
>(
  variables: ProjectsTicketingSystemTypeStatisticsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ProjectsTicketingSystemTypeStatisticsListResponse,
      ProjectsTicketingSystemTypeStatisticsListError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    ProjectsTicketingSystemTypeStatisticsListResponse,
    ProjectsTicketingSystemTypeStatisticsListError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_type_statistics',
      operationId: 'projectsTicketingSystemTypeStatisticsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemTypeStatisticsList(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type ProjectsTicketingSystemTypeStatisticsReadPathParams = {
  projectPk: string;
  ticketingTypePk: string;
};

export type ProjectsTicketingSystemTypeStatisticsReadError =
  Fetcher.ErrorWrapper<undefined>;

export type ProjectsTicketingSystemTypeStatisticsReadVariables = {
  pathParams: ProjectsTicketingSystemTypeStatisticsReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchProjectsTicketingSystemTypeStatisticsRead = (
  variables: ProjectsTicketingSystemTypeStatisticsReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.OneTicketingSystemTypesStatistics,
    ProjectsTicketingSystemTypeStatisticsReadError,
    undefined,
    {},
    {},
    ProjectsTicketingSystemTypeStatisticsReadPathParams
  >({
    url: '/projects/{projectPk}/ticketing_system_type_statistics/{ticketingTypePk}',
    method: 'get',
    ...variables,
    signal,
  });

export const useProjectsTicketingSystemTypeStatisticsRead = <
  TData = Schemas.OneTicketingSystemTypesStatistics
>(
  variables: ProjectsTicketingSystemTypeStatisticsReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OneTicketingSystemTypesStatistics,
      ProjectsTicketingSystemTypeStatisticsReadError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<
    Schemas.OneTicketingSystemTypesStatistics,
    ProjectsTicketingSystemTypeStatisticsReadError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/projects/{projectPk}/ticketing_system_type_statistics/{ticketingTypePk}',
      operationId: 'projectsTicketingSystemTypeStatisticsRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchProjectsTicketingSystemTypeStatisticsRead(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type UserDetailsListError = Fetcher.ErrorWrapper<undefined>;

export type UserDetailsListVariables = OneApiContext['fetcherOptions'];

export const fetchUserDetailsList = (
  variables: UserDetailsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<undefined, UserDetailsListError, undefined, {}, {}, {}>({
    url: '/user-details',
    method: 'get',
    ...variables,
    signal,
  });

export const useUserDetailsList = <TData = undefined>(
  variables: UserDetailsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, UserDetailsListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, UserDetailsListError, TData>({
    queryKey: queryKeyFn({
      path: '/user-details',
      operationId: 'userDetailsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchUserDetailsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UserDetailsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type UserDetailsUpdateVariables = OneApiContext['fetcherOptions'];

export const fetchUserDetailsUpdate = (
  variables: UserDetailsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<undefined, UserDetailsUpdateError, undefined, {}, {}, {}>({
    url: '/user-details',
    method: 'put',
    ...variables,
    signal,
  });

export const useUserDetailsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UserDetailsUpdateError,
      UserDetailsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    UserDetailsUpdateError,
    UserDetailsUpdateVariables
  >({
    mutationFn: (variables: UserDetailsUpdateVariables) =>
      fetchUserDetailsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UserOwnDetailsListError = Fetcher.ErrorWrapper<undefined>;

export type UserOwnDetailsListVariables = OneApiContext['fetcherOptions'];

export const fetchUserOwnDetailsList = (
  variables: UserOwnDetailsListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<undefined, UserOwnDetailsListError, undefined, {}, {}, {}>({
    url: '/user-own-details',
    method: 'get',
    ...variables,
    signal,
  });

export const useUserOwnDetailsList = <TData = undefined>(
  variables: UserOwnDetailsListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, UserOwnDetailsListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<undefined, UserOwnDetailsListError, TData>({
    queryKey: queryKeyFn({
      path: '/user-own-details',
      operationId: 'userOwnDetailsList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchUserOwnDetailsList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UserOwnDetailsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type UserOwnDetailsUpdateVariables = OneApiContext['fetcherOptions'];

export const fetchUserOwnDetailsUpdate = (
  variables: UserOwnDetailsUpdateVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<undefined, UserOwnDetailsUpdateError, undefined, {}, {}, {}>({
    url: '/user-own-details',
    method: 'put',
    ...variables,
    signal,
  });

export const useUserOwnDetailsUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UserOwnDetailsUpdateError,
      UserOwnDetailsUpdateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useOneApiContext();
  return reactQuery.useMutation<
    undefined,
    UserOwnDetailsUpdateError,
    UserOwnDetailsUpdateVariables
  >({
    mutationFn: (variables: UserOwnDetailsUpdateVariables) =>
      fetchUserOwnDetailsUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UsersListError = Fetcher.ErrorWrapper<undefined>;

export type UsersListResponse = Schemas.User[];

export type UsersListVariables = OneApiContext['fetcherOptions'];

/**
 * List all users
 */
export const fetchUsersList = (
  variables: UsersListVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<UsersListResponse, UsersListError, undefined, {}, {}, {}>({
    url: '/users',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * List all users
 */
export const useUsersList = <TData = UsersListResponse>(
  variables: UsersListVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<UsersListResponse, UsersListError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<UsersListResponse, UsersListError, TData>({
    queryKey: queryKeyFn({
      path: '/users',
      operationId: 'usersList',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchUsersList({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UsersReadPathParams = {
  /**
   * A unique integer value identifying this user.
   */
  id: number;
};

export type UsersReadError = Fetcher.ErrorWrapper<undefined>;

export type UsersReadVariables = {
  pathParams: UsersReadPathParams;
} & OneApiContext['fetcherOptions'];

export const fetchUsersRead = (
  variables: UsersReadVariables,
  signal?: AbortSignal
) =>
  oneApiFetch<
    Schemas.User,
    UsersReadError,
    undefined,
    {},
    {},
    UsersReadPathParams
  >({ url: '/users/{id}', method: 'get', ...variables, signal });

export const useUsersRead = <TData = Schemas.User>(
  variables: UsersReadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.User, UsersReadError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useOneApiContext(options);
  return reactQuery.useQuery<Schemas.User, UsersReadError, TData>({
    queryKey: queryKeyFn({
      path: '/users/{id}',
      operationId: 'usersRead',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchUsersRead({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/ai-assistant-config';
      operationId: 'aiAssistantConfigList';
      variables: AiAssistantConfigListVariables;
    }
  | {
      path: '/ai-assistant-config/collaboration';
      operationId: 'aiAssistantConfigCollaborationList';
      variables: AiAssistantConfigCollaborationListVariables;
    }
  | {
      path: '/ai-assistant-config/{id}';
      operationId: 'aiAssistantConfigRead';
      variables: AiAssistantConfigReadVariables;
    }
  | {
      path: '/ai-assistant-config/{id}/history';
      operationId: 'aiAssistantConfigHistoryList';
      variables: AiAssistantConfigHistoryListVariables;
    }
  | {
      path: '/assessment-question/{id}';
      operationId: 'assessmentQuestionRead';
      variables: AssessmentQuestionReadVariables;
    }
  | {
      path: '/comments';
      operationId: 'commentsList';
      variables: CommentsListVariables;
    }
  | {
      path: '/comments/{id}';
      operationId: 'commentsRead';
      variables: CommentsReadVariables;
    }
  | {
      path: '/finding-area/{id}/finding-topics';
      operationId: 'findingAreaFindingTopicsList';
      variables: FindingAreaFindingTopicsListVariables;
    }
  | {
      path: '/finding-area/{id}/rating';
      operationId: 'findingAreaRatingList';
      variables: FindingAreaRatingListVariables;
    }
  | {
      path: '/finding-areas/{id}';
      operationId: 'findingAreasRead';
      variables: FindingAreasReadVariables;
    }
  | {
      path: '/finding-groups/{id}';
      operationId: 'findingGroupsRead';
      variables: FindingGroupsReadVariables;
    }
  | {
      path: '/finding-groups/{id}/finding-areas';
      operationId: 'findingGroupsFindingAreasList';
      variables: FindingGroupsFindingAreasListVariables;
    }
  | {
      path: '/finding-groups/{id}/rating';
      operationId: 'findingGroupsRatingList';
      variables: FindingGroupsRatingListVariables;
    }
  | {
      path: '/finding-groups/{id}/tas_rating';
      operationId: 'findingGroupsTasRatingList';
      variables: FindingGroupsTasRatingListVariables;
    }
  | {
      path: '/finding-reference/{id}';
      operationId: 'findingReferenceRead';
      variables: FindingReferenceReadVariables;
    }
  | {
      path: '/finding-topic-reference/{id}';
      operationId: 'findingTopicReferenceRead';
      variables: FindingTopicReferenceReadVariables;
    }
  | {
      path: '/finding-topic/{id}';
      operationId: 'findingTopicRead';
      variables: FindingTopicReadVariables;
    }
  | {
      path: '/finding-topic/{id}/finding-topic-references';
      operationId: 'findingTopicFindingTopicReferencesList';
      variables: FindingTopicFindingTopicReferencesListVariables;
    }
  | {
      path: '/finding-topic/{id}/maturity-ratings';
      operationId: 'findingTopicMaturityRatingsList';
      variables: FindingTopicMaturityRatingsListVariables;
    }
  | {
      path: '/finding-topic/{topicPk}/assessment-questions';
      operationId: 'findingTopicAssessmentQuestionsList';
      variables: FindingTopicAssessmentQuestionsListVariables;
    }
  | {
      path: '/findings/{id}';
      operationId: 'findingsRead';
      variables: FindingsReadVariables;
    }
  | {
      path: '/findings/{id}/finding-references';
      operationId: 'findingsFindingReferencesList';
      variables: FindingsFindingReferencesListVariables;
    }
  | {
      path: '/maturity-rating/{id}';
      operationId: 'maturityRatingRead';
      variables: MaturityRatingReadVariables;
    }
  | {
      path: '/maturity-view/{ident}/finding-groups-tas-benchmark';
      operationId: 'maturityViewFindingGroupsTasBenchmarkList';
      variables: MaturityViewFindingGroupsTasBenchmarkListVariables;
    }
  | {
      path: '/maturity-view/{id}/finding-groups';
      operationId: 'maturityViewFindingGroupsList';
      variables: MaturityViewFindingGroupsListVariables;
    }
  | {
      path: '/maturity-view/{id}/maturity-bulk';
      operationId: 'maturityViewMaturityBulkRead';
      variables: MaturityViewMaturityBulkReadVariables;
    }
  | {
      path: '/options/{type}';
      operationId: 'optionsRead';
      variables: OptionsReadVariables;
    }
  | {
      path: '/project-collaborators';
      operationId: 'projectCollaboratorsList';
      variables: ProjectCollaboratorsListVariables;
    }
  | {
      path: '/project-team-members/{id}';
      operationId: 'projectTeamMembersRead';
      variables: ProjectTeamMembersReadVariables;
    }
  | {
      path: '/projects';
      operationId: 'projectsList';
      variables: ProjectsListVariables;
    }
  | {
      path: '/projects/excel-export/{projectPk}';
      operationId: 'projectsExcelExportRead';
      variables: ProjectsExcelExportReadVariables;
    }
  | {
      path: '/projects/metadata-options/client-industries';
      operationId: 'projectsMetadataOptionsClientIndustriesList';
      variables: ProjectsMetadataOptionsClientIndustriesListVariables;
    }
  | {
      path: '/projects/metadata-options/industries';
      operationId: 'projectsMetadataOptionsIndustriesList';
      variables: ProjectsMetadataOptionsIndustriesListVariables;
    }
  | {
      path: '/projects/metadata-options/project-types';
      operationId: 'projectsMetadataOptionsProjectTypesList';
      variables: ProjectsMetadataOptionsProjectTypesListVariables;
    }
  | {
      path: '/projects/metadata-options/sizes';
      operationId: 'projectsMetadataOptionsSizesList';
      variables: ProjectsMetadataOptionsSizesListVariables;
    }
  | {
      path: '/projects/metadata-options/stages';
      operationId: 'projectsMetadataOptionsStagesList';
      variables: ProjectsMetadataOptionsStagesListVariables;
    }
  | {
      path: '/projects/{id}';
      operationId: 'projectsRead';
      variables: ProjectsReadVariables;
    }
  | {
      path: '/projects/{id}/dashboard-url';
      operationId: 'projectsDashboardUrlList';
      variables: ProjectsDashboardUrlListVariables;
    }
  | {
      path: '/projects/{id}/export-maturity';
      operationId: 'projectsExportMaturityList';
      variables: ProjectsExportMaturityListVariables;
    }
  | {
      path: '/projects/{id}/files/{pathB64}';
      operationId: 'projectsFilesRead';
      variables: ProjectsFilesReadVariables;
    }
  | {
      path: '/projects/{id}/finding-areas';
      operationId: 'projectsFindingAreasList';
      variables: ProjectsFindingAreasListVariables;
    }
  | {
      path: '/projects/{id}/finding-groups';
      operationId: 'projectsFindingGroupsList';
      variables: ProjectsFindingGroupsListVariables;
    }
  | {
      path: '/projects/{id}/finding-groups/rating';
      operationId: 'projectsFindingGroupsRatingList';
      variables: ProjectsFindingGroupsRatingListVariables;
    }
  | {
      path: '/projects/{id}/findings';
      operationId: 'projectsFindingsList';
      variables: ProjectsFindingsListVariables;
    }
  | {
      path: '/projects/{id}/findings/strengths';
      operationId: 'projectsFindingsStrengthsList';
      variables: ProjectsFindingsStrengthsListVariables;
    }
  | {
      path: '/projects/{id}/findings/weaknesses';
      operationId: 'projectsFindingsWeaknessesList';
      variables: ProjectsFindingsWeaknessesListVariables;
    }
  | {
      path: '/projects/{id}/maturity-bulk';
      operationId: 'projectsMaturityBulkRead';
      variables: ProjectsMaturityBulkReadVariables;
    }
  | {
      path: '/projects/{id}/maturity-views';
      operationId: 'projectsMaturityViewsList';
      variables: ProjectsMaturityViewsListVariables;
    }
  | {
      path: '/projects/{id}/relevant-questions';
      operationId: 'projectsRelevantQuestionsList';
      variables: ProjectsRelevantQuestionsListVariables;
    }
  | {
      path: '/projects/{id}/reporting-charts';
      operationId: 'projectsReportingChartsList';
      variables: ProjectsReportingChartsListVariables;
    }
  | {
      path: '/projects/{id}/service-modules';
      operationId: 'projectsServiceModulesList';
      variables: ProjectsServiceModulesListVariables;
    }
  | {
      path: '/projects/{id}/tas-slides';
      operationId: 'projectsTasSlidesList';
      variables: ProjectsTasSlidesListVariables;
    }
  | {
      path: '/projects/{id}/tas-slides/{tasSlidePk}';
      operationId: 'projectsTasSlidesRead';
      variables: ProjectsTasSlidesReadVariables;
    }
  | {
      path: '/projects/{id}/tas-slots';
      operationId: 'projectsTasSlotsList';
      variables: ProjectsTasSlotsListVariables;
    }
  | {
      path: '/projects/{id}/tas-slots/{tasSlotPk}';
      operationId: 'projectsTasSlotsRead';
      variables: ProjectsTasSlotsReadVariables;
    }
  | {
      path: '/projects/{id}/team-members';
      operationId: 'projectsTeamMembersList';
      variables: ProjectsTeamMembersListVariables;
    }
  | {
      path: '/projects/{id}/tools-status';
      operationId: 'projectsToolsStatusRead';
      variables: ProjectsToolsStatusReadVariables;
    }
  | {
      path: '/projects/{projectPk}/client-specific-questions';
      operationId: 'projectsClientSpecificQuestionsList';
      variables: ProjectsClientSpecificQuestionsListVariables;
    }
  | {
      path: '/projects/{projectPk}/client-specific-questions/{questionPk}';
      operationId: 'projectsClientSpecificQuestionsRead';
      variables: ProjectsClientSpecificQuestionsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/contexts';
      operationId: 'projectsContextsList';
      variables: ProjectsContextsListVariables;
    }
  | {
      path: '/projects/{projectPk}/contexts/{contextPk}';
      operationId: 'projectsContextsRead';
      variables: ProjectsContextsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/contexts/{contextPk}/repositories';
      operationId: 'projectsContextsRepositoriesList';
      variables: ProjectsContextsRepositoriesListVariables;
    }
  | {
      path: '/projects/{projectPk}/facts_ticketing_status';
      operationId: 'projectsFactsTicketingStatusList';
      variables: ProjectsFactsTicketingStatusListVariables;
    }
  | {
      path: '/projects/{projectPk}/facts_ticketing_status/{factsTicketingStatusPk}';
      operationId: 'projectsFactsTicketingStatusRead';
      variables: ProjectsFactsTicketingStatusReadVariables;
    }
  | {
      path: '/projects/{projectPk}/facts_ticketing_type';
      operationId: 'projectsFactsTicketingTypeList';
      variables: ProjectsFactsTicketingTypeListVariables;
    }
  | {
      path: '/projects/{projectPk}/facts_ticketing_type/{factsTicketingTypePk}';
      operationId: 'projectsFactsTicketingTypeRead';
      variables: ProjectsFactsTicketingTypeReadVariables;
    }
  | {
      path: '/projects/{projectPk}/language_relevance';
      operationId: 'projectsLanguageRelevanceList';
      variables: ProjectsLanguageRelevanceListVariables;
    }
  | {
      path: '/projects/{projectPk}/language_relevance/{languagePk}';
      operationId: 'projectsLanguageRelevanceRead';
      variables: ProjectsLanguageRelevanceReadVariables;
    }
  | {
      path: '/projects/{projectPk}/language_statistics';
      operationId: 'projectsLanguageStatisticsList';
      variables: ProjectsLanguageStatisticsListVariables;
    }
  | {
      path: '/projects/{projectPk}/language_statistics/{languagePk}';
      operationId: 'projectsLanguageStatisticsRead';
      variables: ProjectsLanguageStatisticsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/person-from-non-forked-repos';
      operationId: 'projectsPersonFromNonForkedReposList';
      variables: ProjectsPersonFromNonForkedReposListVariables;
    }
  | {
      path: '/projects/{projectPk}/person_accounts';
      operationId: 'projectsPersonAccountsList';
      variables: ProjectsPersonAccountsListVariables;
    }
  | {
      path: '/projects/{projectPk}/person_accounts/{personPk}';
      operationId: 'projectsPersonAccountsRead';
      variables: ProjectsPersonAccountsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/persons';
      operationId: 'projectsPersonsList';
      variables: ProjectsPersonsListVariables;
    }
  | {
      path: '/projects/{projectPk}/persons/{personPk}';
      operationId: 'projectsPersonsRead';
      variables: ProjectsPersonsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/persons/{personPk}/roles';
      operationId: 'projectsPersonsRolesList';
      variables: ProjectsPersonsRolesListVariables;
    }
  | {
      path: '/projects/{projectPk}/repositories';
      operationId: 'projectsRepositoriesList';
      variables: ProjectsRepositoriesListVariables;
    }
  | {
      path: '/projects/{projectPk}/repositories/{repositoryPk}';
      operationId: 'projectsRepositoriesRead';
      variables: ProjectsRepositoriesReadVariables;
    }
  | {
      path: '/projects/{projectPk}/teams';
      operationId: 'projectsTeamsList';
      variables: ProjectsTeamsListVariables;
    }
  | {
      path: '/projects/{projectPk}/teams/{teamPk}';
      operationId: 'projectsTeamsRead';
      variables: ProjectsTeamsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/teams/{teamPk}/persons';
      operationId: 'projectsTeamsPersonsList';
      variables: ProjectsTeamsPersonsListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_project';
      operationId: 'projectsTicketingSystemProjectList';
      variables: ProjectsTicketingSystemProjectListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_project/{ticketingProjectPk}';
      operationId: 'projectsTicketingSystemProjectRead';
      variables: ProjectsTicketingSystemProjectReadVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_statistics';
      operationId: 'projectsTicketingSystemStatisticsList';
      variables: ProjectsTicketingSystemStatisticsListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_statistics/{ticketingProjectPk}';
      operationId: 'projectsTicketingSystemStatisticsRead';
      variables: ProjectsTicketingSystemStatisticsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_status';
      operationId: 'projectsTicketingSystemStatusList';
      variables: ProjectsTicketingSystemStatusListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_status/{ticketingStatusPk}';
      operationId: 'projectsTicketingSystemStatusRead';
      variables: ProjectsTicketingSystemStatusReadVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_status_not_ignored_statistics';
      operationId: 'projectsTicketingSystemStatusNotIgnoredStatisticsList';
      variables: ProjectsTicketingSystemStatusNotIgnoredStatisticsListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_status_statistics';
      operationId: 'projectsTicketingSystemStatusStatisticsList';
      variables: ProjectsTicketingSystemStatusStatisticsListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_status_statistics/{ticketingStatusPk}';
      operationId: 'projectsTicketingSystemStatusStatisticsRead';
      variables: ProjectsTicketingSystemStatusStatisticsReadVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_type';
      operationId: 'projectsTicketingSystemTypeList';
      variables: ProjectsTicketingSystemTypeListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_type/{ticketingTypePk}';
      operationId: 'projectsTicketingSystemTypeRead';
      variables: ProjectsTicketingSystemTypeReadVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_type_not_ignored_statistics';
      operationId: 'projectsTicketingSystemTypeNotIgnoredStatisticsList';
      variables: ProjectsTicketingSystemTypeNotIgnoredStatisticsListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_type_statistics';
      operationId: 'projectsTicketingSystemTypeStatisticsList';
      variables: ProjectsTicketingSystemTypeStatisticsListVariables;
    }
  | {
      path: '/projects/{projectPk}/ticketing_system_type_statistics/{ticketingTypePk}';
      operationId: 'projectsTicketingSystemTypeStatisticsRead';
      variables: ProjectsTicketingSystemTypeStatisticsReadVariables;
    }
  | {
      path: '/user-details';
      operationId: 'userDetailsList';
      variables: UserDetailsListVariables;
    }
  | {
      path: '/user-own-details';
      operationId: 'userOwnDetailsList';
      variables: UserOwnDetailsListVariables;
    }
  | {
      path: '/users';
      operationId: 'usersList';
      variables: UsersListVariables;
    }
  | {
      path: '/users/{id}';
      operationId: 'usersRead';
      variables: UsersReadVariables;
    };
