import { Stack } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import {
  Category,
  CategoryBadge,
  CategoryIconLess,
  CategoryIconMore,
  CategoryText,
} from './styles';

type TSidebarCategoryProps = {
  name: string;
  icon: ReactNode;
  isOpen: boolean;
  isCollapsable: boolean;
  badge: ReactNode;
  isDrawerOpen: boolean;
} & any;
const SidebarCategory: FC<TSidebarCategoryProps> = ({
  name,
  icon,
  isOpen,
  isCollapsable,
  badge,
  isDrawerOpen,
  ...rest
}) => {
  return (
    <Category {...rest}>
      <Stack mr={isDrawerOpen ? 0 : 2}>{icon}</Stack>
      <CategoryText>{name}</CategoryText>
      {isCollapsable && (isOpen ? <CategoryIconLess /> : <CategoryIconMore />)}
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  );
};
export default SidebarCategory;
