import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Chip,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { rgba } from 'polished';
import { DRAWER_WIDTH } from '../../../layouts/dashboard/DashboardLayout.style';

export const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
    background-color: ${(props) => props.theme.sidebar.background};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    white-space: nowrap;
    .MuiDrawer-paper {
      width: ${(props) => (props.open ? DRAWER_WIDTH : 48)}px;
      transition: width 0.3s;
      overflow-x: hidden;
    }
  }
`;

export const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  .ps__rail-y {
    width: unset;
  }
`;

export const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

export const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

export const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(4)};
  padding-right: ${(props) => props.theme.spacing(4)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props: any) => props.activeClassName} {
    background-color: ${(props) => props.theme.palette.primary.main};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

export const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.sidebar.category.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(2)};
  }
`;

export const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const SidebarStyleLink: typeof ListItem = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(14)};
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props: any) => props.activeClassName} {
    background-color: ${(props) => props.theme.palette.primary.main};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

export const SidebarStyleLinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

export const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

export const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

export const SidebarSection = styled(Typography)<{ isSideBarOpen: boolean }>`
  color: ${(props) =>
    props.isSideBarOpen
      ? props.theme.sidebar.section.header.color
      : props.theme.sidebar.background};
  padding: ${(props) => props.theme.spacing(6)}
    ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(3)};
  border-bottom: solid 2px
    ${(props) => props.theme.sidebar.section.divider.color};
  text-transform: uppercase;
  opacity: 0.9;
  display: block;
`;
