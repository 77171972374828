import React from 'react';
import {
  onePermGroups as onePermsGroups,
  onePerms,
  projectRoleGroups,
  projectRoles,
} from '../utils/oneAuthorization';
import Icon from '../components/Icon';
import { Redirect } from 'react-router-dom';
import Dashboard from '../pages/dashboard';

const ListQuestionnaires = React.lazy(
  () => import('../pages/questionnaire/questionnairesList')
);
const AllQuestions = React.lazy(
  () => import('../pages/questionnaire/allQuestions')
);
const ResponsesView = React.lazy(
  () => import('../pages/questionnaire/responsesView')
);
const ProjectsAdmin = React.lazy(
  () => import('../pages/projects/ProjectsAdmin')
);
const ListUsers = React.lazy(() => import('../pages/users/ListUsers'));
const FindingAreaList = React.lazy(
  () => import('../pages/findingAreas/admin/FindingAreaList')
);
const ProjectSummary = React.lazy(() => import('../pages/reportSummary'));
const Page404 = React.lazy(() => import('../pages/public/Page404'));
const Page500 = React.lazy(() => import('../pages/public/Page500'));
const ProjectOverview = React.lazy(
  () => import('../pages/projects/overview/ProjectOverview')
);
const Notes = React.lazy(() => import('../pages/notes'));
const Welcome = React.lazy(() => import('../pages/public/Welcome'));
const MaturityModel = React.lazy(() => import('../pages/maturityModel'));
const Findings = React.lazy(() => import('../pages/findings'));
const ProjectSetup = React.lazy(() => import('../pages/projectSetup'));
const Conclusion = React.lazy(() => import('../pages/conclusion'));
const ClientSpecificQuestions = React.lazy(
  () => import('../pages/clientSpecificQuestions')
);
const FactsExplorer = React.lazy(() => import('../pages/factsExplorer'));
const Profile = React.lazy(() => import('../pages/profile'));
const OneConfig = React.lazy(() => import('../pages/oneConfiguration'));

type TRoute = {
  header?: string;
  id?: string;
  name?: string;
  navPath?: string;
  path: string | string[];
  component?: React.FC;
  validRoles?: string | string[];
  validPerms?: string | string[];
  icon?: React.ReactNode;
  children?: TRoute[];
};
export type TPublicRoute = TRoute;
// all routes that are protected by project access
export type TProtectedRoute = TRoute & {
  tabTitle?: string;
  children?: TProtectedRoute[];
};

const dashboard = {
  id: 'Dashboard',
  path: ['/'],
  component: Dashboard,
  validPerms: [onePerms.COLLABORATE_ON_PROJECTS],
};

const profile: TPublicRoute = {
  path: ['/profile'],
  component: Profile,
};

const projectSelection: TPublicRoute = {
  id: 'Projects',
  path: ['/projects'],
  component: ProjectOverview,
  validPerms: [onePerms.COLLABORATE_ON_PROJECTS],
};

const projectMainPage: TPublicRoute = {
  id: 'Project',
  path: ['/projects/:projectIdent'],
  component: () => <Redirect to={'/reporting'} />,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
};

const reportingRoutes: TProtectedRoute = {
  header: 'Reporting',
  id: 'Report Summary',
  path: ['/projects/:projectIdent/reporting', '/reporting'],
  icon: <Icon category="navigation" name="nav-report_summary" />,
  component: ProjectSummary,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
  tabTitle: 'Report',
};

const findingRoutes: TProtectedRoute = {
  id: 'Findings',
  path: [
    '/projects/:projectIdent/findings/:findingId?',
    '/findings/:findingId?',
  ],
  navPath: '/projects/:projectIdent/findings',
  icon: <Icon category="navigation" name="nav-findings" />,
  component: Findings,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
  tabTitle: 'Findings',
};

const maturityHome: TProtectedRoute = {
  id: 'Maturity Model',
  path: ['/projects/:projectIdent/maturity-model', '/maturity-model'],
  icon: <Icon category="navigation" name="nav-maturity_rating" />,
  component: MaturityModel,
  validRoles: projectRoleGroups.REPORT_VIEWERS,
  tabTitle: 'Maturity',
};

const clientSpecificQuestions: TProtectedRoute = {
  id: 'Client Specific Questions',
  path: [
    '/projects/:projectIdent/client-specific-questions/:questionId?',
    '/client-specific-questions/:questionId?',
  ],
  navPath: '/projects/:projectIdent/client-specific-questions',
  icon: <Icon category="navigation" name="nav-questionnaire" />,
  name: 'Client Specific Questions',
  component: ClientSpecificQuestions,
  validRoles: [projectRoles.PROJECT_ADMIN, projectRoles.PROJECT_ANALYST],
  tabTitle: 'CSQ',
};

const questionnaireDetailsRoutes: TProtectedRoute = {
  path: [
    '/projects/:projectIdent/questionnaires/answers/:id',
    '/questionnaires/answers/:id',
  ],
  name: 'Questionnaire Answer',
  component: ResponsesView,
  validPerms: [onePerms.POLL_AUDIT_ANSWERS],
  tabTitle: 'Answers',
};
const questionnairesAllQuestions: TProtectedRoute = {
  path: [
    '/projects/:projectIdent/questionnaires/questions',
    '/questionnaires/questions',
  ],
  name: "Questionnaire's Questions",
  component: AllQuestions,
  validRoles: [projectRoles.PROJECT_ADMIN, projectRoles.PROJECT_ANALYST],
  tabTitle: 'Q&A',
};

const assessmentSourcesArea: TProtectedRoute[] = [
  {
    header: 'Assessment Sources',
    id: 'Facts Explorer',
    path: [
      '/projects/:projectIdent/facts',
      '/projects/:projectIdent/facts/:tab?',
      '/facts:tab?',
    ],
    component: FactsExplorer,
    icon: <Icon category="navigation" name="nav-snippet" />,
    tabTitle: 'Facts',
  },
  {
    id: 'Questionnaires',
    path: ['/projects/:projectIdent/questionnaires', '/questionnaires'],
    icon: <Icon category="navigation" name="nav-questionnaire" />,
    component: ListQuestionnaires,
    validRoles: [projectRoles.PROJECT_ADMIN, projectRoles.PROJECT_ANALYST],
    tabTitle: 'Q&A',
  },
  {
    id: 'Notes',
    path: ['/projects/:projectIdent/notes', '/notes'],
    icon: <Icon category="navigation" name="nav-interview_notes" />,
    component: Notes,
    validPerms: [onePerms.ONE_EXPERIMENTAL_FEATURES],
    validRoles: projectRoleGroups.REPORT_VIEWERS,
    tabTitle: 'Notes',
  },
];

const projectSetupRoutes: TProtectedRoute[] = [
  {
    path: ['/projects/:projectIdent/project-setup/team', '/project-setup/team'],
    component: () => <ProjectSetup defaultTab="TEAM" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
    tabTitle: 'Setup',
  },
  {
    path: [
      '/projects/:projectIdent/project-setup/client-and-target',
      '/project-setup/client-and-target',
    ],
    component: () => <ProjectSetup defaultTab="CLIENT_AND_TARGET" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
    tabTitle: 'Setup',
  },
  {
    path: [
      '/projects/:projectIdent/project-setup/shortcuts',
      '/project-setup/shortcuts',
    ],
    component: () => <ProjectSetup defaultTab="SHORTCUTS" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
    tabTitle: 'Setup',
  },
  {
    path: [
      '/projects/:projectIdent/project-setup/maturity-grid',
      '/project-setup/maturity-grid',
    ],
    component: () => <ProjectSetup defaultTab="MATURITY_GRID" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
    tabTitle: 'Setup',
  },
];
const projectAdminArea: TProtectedRoute[] = [
  {
    header: 'Project Admin',
    id: 'Project Setup',
    path: [
      '/projects/:projectIdent/project-setup',
      '/projects/:projectIdent/project-setup/global-settings',
      '/project-setup',
      '/project-setup/global-settings',
      ...projectSetupRoutes.map((item) => item.path).flat(),
    ],
    icon: <Icon category="navigation" name="nav-project_setup" />,
    component: () => <ProjectSetup defaultTab="GLOBAL_SETTINGS" />,
    validRoles: [projectRoles.PROJECT_ADMIN],
    tabTitle: 'Setup',
  },
  {
    id: 'Conclusion',
    path: ['/projects/:projectIdent/conclusion', '/conclusion'],
    icon: <Icon category="navigation" name="nav-archive" />,
    component: () => <Conclusion />,
    validRoles: [projectRoles.PROJECT_ADMIN],
    tabTitle: 'Conclusion',
  },
];

const adminRoutes: TProtectedRoute = {
  header: 'ONE Admin',
  id: 'ONE Admin',
  path: '/admin',
  icon: <Icon category="navigation" name="nav-project_setup" />,
  tabTitle: 'Admin',
  children: [
    {
      path: ['/admin/projects'],
      name: 'Projects',
      component: ProjectsAdmin,
      validPerms: [...onePermsGroups.CREATE_PROJECTS],
      tabTitle: 'Admin - Projects',
    },
    {
      path: ['/admin/users'],
      name: 'Users',
      component: ListUsers,
      validPerms: [onePerms.VIEW_USERS],
      tabTitle: 'Admin - Users',
    },
    {
      path: ['/admin/finding-areas'],
      name: 'Finding Areas',
      component: FindingAreaList,
      validPerms: [onePerms.ADMIN_REFERENCE_DATA],
      tabTitle: 'Admin - Finding Areas',
    },
    {
      path: ['/admin/one-config'],
      name: 'ONE Config',
      component: () => <OneConfig defaultTab={'FINDINGS_ASSISTANT'} />,
      validPerms: [onePerms.SUPER_ADMIN, onePerms.ONE_PROMPT_ENGINEER],
      tabTitle: 'Admin - ONE Config',
    },
  ],
};

const publicRoutes: TPublicRoute = {
  id: 'Auth',
  path: '/public',
  children: [
    {
      path: '/public/404',
      name: '404 Page',
      component: Page404,
    },
    {
      path: '/public/500',
      name: '500 Page',
      component: Page500,
    },
    {
      path: '/public/welcome',
      name: 'Welcome',
      component: Welcome,
    },
  ],
};

const navigationRoutes = [
  reportingRoutes,
  findingRoutes,
  maturityHome,
  clientSpecificQuestions,
  ...assessmentSourcesArea,
  ...projectAdminArea,
  adminRoutes,
];

export const protectedRoutes = [
  reportingRoutes,
  findingRoutes,
  maturityHome,
  ...assessmentSourcesArea,
  ...projectSetupRoutes,
  ...projectAdminArea,
  questionnaireDetailsRoutes,
  questionnairesAllQuestions,
  adminRoutes,
  projectMainPage,
  projectSelection,
  dashboard,
  profile,
  clientSpecificQuestions,
];

export const unprotectedRoutes = [publicRoutes];

export default navigationRoutes;
